import React, {useCallback, useMemo} from 'react';
import cx from 'clsx';
import PropTypes from 'prop-types';
import {FormControl, FormHelperText, TextareaAutosize} from '@material-ui/core';
import {touch} from 'redux-form';
import {useDispatch} from 'react-redux';

/*
 * This component renders a textarea field for redux-form
 */
const TextAreaField = props => {
  const {
    meta,
    input: {value, onChange, name, onBlur, onFocus},
    label,
    excludeLabel,
    placeholder,
    autosize,
    children,
    ...custom
  } = props;
  const {touched, error} = meta;
  const dispatch = useDispatch();

  const handleChange = useCallback(
    e => {
      if (!touched) {
        dispatch(touch(meta.form, name));
      }
      onChange(e.target.value);
    },
    [dispatch, meta.form, name, onChange, touched]
  );

  const textAreaProps = useMemo(
    () => ({
      className: 'form-control',
      placeholder: placeholder,
      ...custom,
      name,
      value,
      onChange: handleChange,
      onBlur,
      onFocus,
    }),
    [custom, handleChange, name, onBlur, onFocus, placeholder, value]
  );

  return (
    <FormControl className="form-group d-flex" error={touched && !!error}>
      {label && !excludeLabel && (
        <span className="small-label pb-2">{label}</span>
      )}
      {autosize && (
        <TextareaAutosize
          {...textAreaProps}
          className={cx('form-control', {'border-bottom-flat': !!children})}
        />
      )}
      {!autosize && (
        <textarea
          {...textAreaProps}
          className={cx('form-control', {'border-bottom-flat': !!children})}
        />
      )}
      {children}
      {touched && error ? <FormHelperText>{error}</FormHelperText> : null}
    </FormControl>
  );
};

TextAreaField.propTypes = {
  placeholder: PropTypes.string,
  autosize: PropTypes.bool,
};

export default TextAreaField;
