import {WrappedFieldProps} from 'redux-form';
import CustomSwitch from '../../Common/Switch';

const SwitchField = ({
  input: {value, name, onChange},
  switchProps,
}: WrappedFieldProps & {
  switchProps: unknown;
}) => {
  return (
    <CustomSwitch
      {...switchProps}
      name={name}
      isChecked={value}
      handleOptionCheck={onChange}
      option="isTrustableSeller"
    />
  );
};

export default SwitchField;
