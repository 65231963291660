import {useIntl} from 'react-intl';
import {Box, Snackbar, SnackbarContent} from '@material-ui/core';
import {createStyles, makeStyles} from '@material-ui/styles';
import {ITheme} from '../../constants/theme';
import CustomButton from '../Common/Button';

/**
 * Save Feature
 */

export type SaveFeatureProps = {
  open: boolean;
  num: number;
  onClose?: () => void;
  onSave: (data: unknown) => void;
  saveButtonType?: 'submit' | 'reset' | 'button' | undefined;
  saveButtonTitle?: string;
  description?: string;
  disableSave?: boolean;
};

export const SaveFeature = (props: SaveFeatureProps) => {
  const {
    num,
    open,
    onClose,
    onSave,
    saveButtonType,
    saveButtonTitle,
    description,
    disableSave,
  } = props;
  const intl = useIntl();

  const classes = useStyles();

  // Close popup
  const handleClose = () => {
    onClose && onClose();
  };

  // The display none trick is to avoid UI delays when display this component because it's being used with complex forms.
  return (
    <div style={{display: open ? 'block' : 'none'}}>
      <Snackbar
        disableWindowBlurListener
        classes={classes}
        style={{zIndex: 1000}}
        open={true}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        children={
          num > 0 && (
            <SnackbarContent
              data-testid={'save-feature-content-wrapper'}
              message={
                description ??
                intl.formatMessage(
                  {id: 'dashboard.num_changed_popup_message'},
                  {num: num}
                )
              }
              action={
                <Box
                  className="align-items-stretch align-items-lg-center w-100 justify-content-end flex-wrap"
                  sx={{display: 'flex', flexDirection: 'row'}}
                >
                  <CustomButton
                    link
                    onClick={handleClose}
                    title={intl.formatMessage({id: 'actions.cancel'})}
                  />
                  <CustomButton
                    primary
                    disabled={disableSave}
                    onClick={onSave}
                    data-testid="save-feature-content-btn"
                    type={saveButtonType}
                    title={
                      saveButtonTitle ??
                      intl.formatMessage({id: 'actions.save'})
                    }
                  />
                </Box>
              }
            />
          )
        }
      />
    </div>
  );
};

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    root: {
      left: '46px !important',
      right: '50px !important',
      bottom: '24px',
      marginLeft: 240,
      [theme.breakpoints.down('lg')]: {
        marginLeft: 86,
        left: '25px !important',
        right: '25px !important',
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
        left: '10px !important',
        right: '10px !important',
        bottom: '8px',
      },
      '& .MuiSnackbarContent-root': {
        width: '80vw',
        flexGrow: 1,
        padding: '15px 20px',
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        boxShadow: theme.shadows[3],
        borderRadius: 8,
        [theme.breakpoints.down('md')]: {
          alignItems: 'flex-start',
          flexDirection: 'column',
        },
        '& .MuiSnackbarContent-action': {
          marginRight: 0,
          marginLeft: 'auto',
          [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            paddingLeft: 0,
            paddingTop: 10,
            width: '100%',
          },
          '& .MuiButtonBase-root': {
            justifyContent: 'center',
            fontSize: '15px',
            [theme.breakpoints.up('md')]: {
              '& + .MuiButtonBase-root': {
                marginLeft: '16px',
              },
            },
            [theme.breakpoints.down('md')]: {
              width: '100%',
            },
          },
          '& .MuiButton-contained': {
            [theme.breakpoints.up('lg')]: {
              minWidth: 97,
            },
          },
        },
      },
    },
    disabledButton: {
      backgroundColor: theme.palette.background.disabled,
      '&:hover': {
        backgroundColor: theme.palette.background.disabled,
      },
    },
  })
);
