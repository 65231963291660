import {useMemo, useState} from 'react';
import {Field, reduxForm} from 'redux-form';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Box, Typography} from '@material-ui/core';
import InputField from '../../Form/Fields/InputField';
import {
  DELIVERY_MARK_ICON,
  INSTANT_MARK_ICON,
  PREMIUM_MARK_ICON,
  PRO_MARK_ICON,
  TRUST_MARK_ICON,
} from '../../../constants/images';
import {connect, useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  email,
  phoneNumber,
  required,
  validateBeforeSubmit,
} from '../../../validation/validators';
import {API_ADMIN_USER_EDIT_DETAIL_REQUESTED} from '../../../constants/actionTypes';
import PermissionContainer from '../../Common/PermissionContainer';
import {getYearsBetweenDates} from '../../../constants/dates';
import ConfirmationPopup from '../../Sales/Details/Popups/ConfirmationPopup';
import {checkExistingEmail} from '../../../connectivity/user.api';
import useProgressModal from '../../../hooks/app/useProgressModal';
import {selectToken} from '../../../store/slices/authSlice';
import usePermission from '../../../Auth/usePermission';
import {SaveFeature} from '../../Widgets/SaveFeature';
import {get} from 'lodash';
import SwitchField from '../../Form/Fields/SwitchField';
import {isEmail} from 'class-validator';
import AutocompleteField from '../../Form/Fields/AutocompleteField';
import RadioGroupField from '../../Form/Fields/RadioGroupField';
import {enqueueSnackbarError} from '../../../actions/snackbarActions';
import {countDifferences} from '../../../utils/functions';

const validate = values => {
  const errors: any = {};
  // validate required fields and values
  errors.firstname = required(values.firstname);
  errors.lastname = required(values.lastname);
  errors.email = required(values.email) || email(values.email);
  errors.phoneNumber = phoneNumber(
    values.phoneNumber,
    'The phone number format is invalid'
  );
  return errors;
};

let ProfileDetailsGeneral = props => {
  const token = useSelector(selectToken);
  const {intl, user, change, form} = props;
  const initialValues = useSelector(state =>
    get(state, `form.${form}.initial`, {})
  );
  const values = useSelector(state => get(state, `form.${form}.values`, {}));
  const changedFields = useMemo(
    () => countDifferences(values, initialValues),
    [initialValues, values]
  );
  const dispatch = useDispatch();

  // show confirmation popup to delete existing user
  const [eraseExistingUserEmailPopoup, setEraseExistingUserEmailPopoup] =
    useState(false);

  // the id of the user thqt we will erase email for
  const [existingUserId, setExistingUserId] = useState(null);

  // progress modal
  const {show: showSpinner, hide: hideSpinner} = useProgressModal();

  const {hasAnyPermission} = usePermission();
  const hasEditPermission = hasAnyPermission(['admin_edit_user']);

  // Handle submit of data for various events
  const submit = values => {
    if (!hasEditPermission) {
      dispatch(
        enqueueSnackbarError({message: "You don't have permission to edit"})
      );
      return;
    }
    // validate and make sure empty fields are not submitted
    const errors = validate(values);
    validateBeforeSubmit(errors);

    dispatch({
      type: API_ADMIN_USER_EDIT_DETAIL_REQUESTED,
      payload: {
        ...values,
        userId: user.id,
      },
    });
  };

  // get list of years from 1900 -> (currentyear - 18)
  const yearsOptions = useMemo(() => getYearsBetweenDates(), []);

  const handleEmailChange = async e => {
    const newEmail = e.target.value;

    if (newEmail && isEmail(newEmail) && user.email !== newEmail) {
      // send request to check if the email already exist in DB
      let existingUser = null;

      // show loading spinner
      showSpinner();

      // do request
      existingUser = await checkExistingEmail({
        id: user.id,
        email: newEmail,
        token,
      });
      //
      setExistingUserId(existingUser?.id);

      // hide loading spinner
      hideSpinner();

      // if user exist, show confirmation popup
      if (existingUser) {
        setEraseExistingUserEmailPopoup(true);
      }
    }
  };

  const handleAcceptEraseUserEmail = e => {
    // hide popup
    setEraseExistingUserEmailPopoup(false);
  };

  const handleDeclineEraseUserEmail = () => {
    change('email', user.email);
  };

  const optionFields = useMemo(
    () => [
      {
        title: intl.formatMessage({
          id: 'dashboard.sales.marks.trusted_seller',
        }),
        icon: TRUST_MARK_ICON,
        name: 'isTrustableSeller',
      },
      {
        title: intl.formatMessage({
          id: 'dashboard.sales.marks.custom_delivery',
        }),
        icon: DELIVERY_MARK_ICON,
        name: 'isCustomDelivery',
      },
      {
        title: intl.formatMessage({
          id: 'dashboard.sales.marks.premium_seller',
        }),
        icon: PREMIUM_MARK_ICON,
        name: 'isPremiumSeller',
      },
      {
        title: intl.formatMessage({
          id: 'dashboard.sales.marks.pro_features',
        }),
        icon: PRO_MARK_ICON,
        name: 'isFanpassPro',
      },
      {
        title: intl.formatMessage({
          id: 'dashboard.sales.marks.instant_confirmation',
        }),
        icon: INSTANT_MARK_ICON,
        name: 'isInstantConfirm',
      },
    ],
    [intl]
  );

  return (
    <PermissionContainer permissions={['admin_listing_user_details']}>
      {/*Set earase existing user Popup*/}
      <ConfirmationPopup
        open={eraseExistingUserEmailPopoup}
        setOpen={setEraseExistingUserEmailPopoup}
        onAccept={handleAcceptEraseUserEmail}
        onClose={handleDeclineEraseUserEmail}
        description={intl.formatMessage(
          {
            id: 'dashboard.user_details.actions.erase_existing_user_email',
          },
          {
            link: `<a target='_blank' href='/admin-dashboard/users/${existingUserId}'>${existingUserId}</a>`,
          }
        )}
      />
      <div className="row gx-3">
        {/* Left Part */}
        <div className="col-lg-4">
          <form>
            <div className="card details-card h-100">
              <div className="card-body">
                {/*First name*/}
                <div className="form-group static">
                  <span className="small-label">
                    {intl.formatMessage({
                      id: 'dashboard.user_details.tab_general.first_name',
                    })}
                  </span>
                  <Field
                    component={InputField}
                    name="firstname"
                    outlined
                    disabled={!hasEditPermission}
                  />
                </div>
                {/*Last name*/}
                <div className="form-group static">
                  <span className="small-label">
                    {intl.formatMessage({
                      id: 'dashboard.user_details.tab_general.last_name',
                    })}
                  </span>
                  <Field
                    component={InputField}
                    name="lastname"
                    outlined
                    disabled={!hasEditPermission}
                  />
                </div>
                {/*Email address*/}
                <div className="form-group static">
                  <span className="small-label">
                    {intl.formatMessage({
                      id: 'dashboard.user_details.tab_general.email_address',
                    })}
                  </span>
                  <Field
                    component={InputField}
                    name="email"
                    type="email"
                    outlined
                    onBlur={handleEmailChange}
                    disabled={!hasEditPermission}
                  />
                </div>
                {/*Phone number*/}
                <div className="form-group static">
                  <span className="small-label">
                    {intl.formatMessage({
                      id: 'dashboard.user_details.tab_general.phone_number',
                    })}
                  </span>
                  <Field
                    component={InputField}
                    outlined
                    name="phoneNumber"
                    formGroupClass="form-group static"
                    disabled={!hasEditPermission}
                  />
                </div>
                {/*Gender*/}
                <div className="form-group static">
                  <Field
                    component={RadioGroupField}
                    name="gender"
                    legend={intl.formatMessage({
                      id: 'dashboard.user_details.tab_general.gender',
                    })}
                    items={[
                      {
                        label: intl.formatMessage({
                          id: 'dashboard.user_details.tab_general.male',
                        }),
                        value: 'mr',
                      },
                      {
                        label: intl.formatMessage({
                          id: 'dashboard.user_details.tab_general.female',
                        }),
                        value: 'miss',
                      },
                    ]}
                    radioProps={{
                      disabled: !hasEditPermission,
                      classes: {label: 'reset-spacing', root: 'reset-spacing'},
                    }}
                    radioGroupProps
                  />
                </div>
                {/*Date of birth*/}
                <div className="form-group date-input">
                  <span className="small-label">
                    {intl.formatMessage({
                      id: 'dashboard.user_details.tab_general.date_birth',
                    })}
                  </span>
                  <Field
                    component={AutocompleteField}
                    options={yearsOptions.map(year => ({
                      label: year,
                      value: year,
                    }))}
                    name="birthyear"
                    disabled={!hasEditPermission}
                  />
                </div>
                {/* Default language */}
                <div className="form-group mb-0">
                  <Box pb={1}>
                    <span className="small-label pb-0">
                      <FormattedMessage id="dashboard.user_details.tab_general.default_language" />
                    </span>
                  </Box>
                  <Field
                    component={AutocompleteField}
                    options={[
                      {label: 'English', value: 'en_GB'},
                      {label: 'French', value: 'fr_FR'},
                    ]}
                    name="locale"
                    disabled={!hasEditPermission}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        {/*Center Part*/}
        <div className="col-lg-4">
          <div className="card details-card mt-3 mt-lg-0">
            <div className="card-body">
              {optionFields.map(optionField => (
                <div
                  className="row align-items-center pb-3 mb-1"
                  key={optionField.name}
                >
                  <div className="col">
                    <div className="d-flex align-items-center">
                      <span className="mark">{optionField.icon}</span>
                      <Typography variant="body2" className="ms-2">
                        {optionField.title}
                      </Typography>
                    </div>
                  </div>
                  <div className="col-auto">
                    <Field
                      name={optionField.name}
                      component={SwitchField}
                      switchProps={{
                        disabled: !hasEditPermission,
                      }}
                    />
                  </div>
                </div>
              ))}

              {/*Created at*/}
              <div className="form-group static">
                <span className="small-label">
                  {intl.formatMessage({
                    id: 'dashboard.user_details.tab_general.created_at',
                  })}
                </span>
                {/* format createdAt date */}
                <Typography variant="body2">
                  {moment(user.createdAt).isValid()
                    ? `${moment(user.createdAt).format('MMM DD, YYYY - hh:mm')} (GMT)`
                    : '-'}
                </Typography>
              </div>

              {/*Last log in*/}
              <div className="form-group mb-0">
                <span className="small-label">
                  {intl.formatMessage({
                    id: 'dashboard.user_details.tab_general.last_login',
                  })}
                </span>
                {/* format last login date */}
                <Typography variant="body2">
                  {moment(user.lastLogin).isValid()
                    ? `${moment(user.lastLogin).format('MMM DD, YYYY - hh:mm')} (GMT)`
                    : '-'}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SaveFeature
        data-testid="save-feature-content-wrapper"
        onSave={() => props.handleSubmit(submit)()}
        num={changedFields}
        onClose={() => props.reset()}
        open={changedFields > 0}
        disableSave={!hasEditPermission || !props.valid}
      />
    </PermissionContainer>
  );
};

// Validate user props
ProfileDetailsGeneral.propTypes = {
  user: PropTypes.object,
};

ProfileDetailsGeneral = reduxForm({
  form: 'ProfileDetailsGeneralForm',
  enableReinitialize: true,
  validate,
})(ProfileDetailsGeneral);

// Pass user details to form as initial values
const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: {
      firstname: ownProps.user.firstname,
      lastname: ownProps.user.lastname,
      email: ownProps.user.email,
      phoneNumber: ownProps.user.phoneNumber,
      isTrustableSeller: ownProps.user.isTrustableSeller,
      isCustomDelivery: ownProps.user.isCustomDelivery,
      isPremiumSeller: ownProps.user.isPremiumSeller,
      isFanpassPro: ownProps.user.isFanpassPro,
      isInstantConfirm: ownProps.user.isInstantConfirm,
      locale: ownProps.user.locale,
      birthyear: ownProps.user.birthyear,
      gender: ownProps.user.gender,
    },
  };
};

export default connect(mapStateToProps)(injectIntl(ProfileDetailsGeneral));
