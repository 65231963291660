import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import {Box} from '@material-ui/core';
import PropTypes from 'prop-types';

import {CalendarIcon, WorldLocationIcon} from '../../../constants/images';
import StatusStateIndicator from '../../Common/Indicator/StatusStateIndicator';
import useDateHelper from '../../../hooks/utils/useDateHelper';
import PermissionContainer from '../../Common/PermissionContainer';
import {BUYER_IP_PERMISSIONS} from '../../../constants/permissions';
import Actions from './DetailActions';
import * as SALE from '../../../constants/sales';
import Button from '../../Common/Button';
import {
  PauseGrayTableIcon as PauseIcon,
  ResumeIcon,
} from '../../../constants/images';
import {getSaleStatus, getSellingPrice} from '../../../utils/saleHelper';
import HintTooltip from '../../Common/HintTooltip';
import ListTooltip from '../../Widgets/ListTooltip';
import {PAYOUT_STATUSES} from '../../../constants/enums';

const SaleDetailsHeader = props => {
  // const sale = useSelector(state => state.saleReducer.details);
  const {
    sale,
    setCustom,
    setCancelPopup,
    setAsCancelledPopup,
    setRefundPopup,
    setPenaltyPopup,
    setPaymentPopup,
    setAsDeliveredPopup,
    sellerPaymentMethods,
    onPausePayout,
    onResumePayout,
  } = props;
  const intl = useIntl();
  const dateHelper = useDateHelper();

  //Allow show cancel sales in the action dropdown
  const [allowCancel, setAllowCancel] = useState(false);
  //Allow Set as cancelled in the action dropdown
  const [allowAsCancelled, setAllowAsCancelled] = useState(false);
  //Allow show penalty option in the action dropdown
  const [allowPenalty, setAllowPenalty] = useState(false);
  //Allow show refund option in the action dropdown
  const [allowRefund, setAllowRefund] = useState(false);
  //Allow show refund option in the action dropdown
  const [allowCreatePayment, setAllowCreatePayment] = useState(false);
  const [createPaymentEnabled, setCreatePaymentEnabled] = useState(false);

  //Allow pause/resume payout
  const [allowPausePayout, setAllowPausePayout] = useState(false);
  const [allowResumePayout, setAllowResumePayout] = useState(false);

  //Allow set as delivered in the action dropdown
  const [allowAsDelivered, setAllowAsDelivered] = useState(false);

  useEffect(() => {
    setCustom(sale?.customDeliveryMode);

    // show cancel sales in action dropdown if the status is confirmation_pending
    setAllowCancel(sale?.status === SALE.STATUS_CONFIRMATION_PENDING);

    //show set as cancelled in action dropdown if the status is included in the allowed status list
    setAllowAsCancelled(
      sale?.status && SALE.SET_AS_CANCELLED_STATUS_ALLOWED.includes(sale.status)
    );

    // show add penalty in action dropdown if the status is included in the allowed status list
    setAllowPenalty(
      sale?.status && SALE.PENALTY_STATUS_ALLOWED.includes(sale.status)
    );

    // show add refund in action dropdown if the status is included in the allowed status list
    setAllowRefund(
      sale?.status && SALE.REFUND_STATUS_ALLOWED.includes(sale.status)
    );

    // show create payment in action dropdown if the status is included in the allowed status list
    setAllowCreatePayment(
      sale?.status && SALE.CREATE_PAYMENT_STATUS_ALLOWED.includes(sale.status)
    );

    // show set as delivered in action dropdown if the status is included in the allowed status list
    setAllowAsDelivered(
      sale?.status && SALE.SET_AS_DELIVERED_STATUS_ALLOWED.includes(sale.status)
    );

    //show pause payout in action dropdown if allowed
    setAllowPausePayout(sale?.payoutCanPaused === true);

    //show resume payout in action dropdown if allowed
    setAllowResumePayout(sale?.payoutCanResumed === true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sale]);

  useEffect(() => {
    setCreatePaymentEnabled(sellerPaymentMethods.length > 0);
  }, [sellerPaymentMethods]);

  let transaction = sale?.transaction;
  if (Array.isArray(transaction)) {
    transaction = transaction[0];
  }

  // To check and get explaining why the transaction cannot be Paused
  const checkPayoutCannotBePaused = () => {
    const status = sale?.payout?.status;
    const sellingPrice = getSellingPrice(sale);

    let reason;
    let pauseNotAppreciated = false;
    // Penalty is fully applied on current sale ?
    const penaltyFullyAppliedSale =
      sale?.currentPenaltyAmount > 0 &&
      sale?.currentPenaltyAmount === sellingPrice;

    switch (status) {
      case PAYOUT_STATUSES['in-transit'].value:
        reason = intl.formatMessage({
          id: 'dashboard.transactions.payout.cannotPaused_tooltip.reason_in_transit',
        });
        pauseNotAppreciated = true;
        break;
      case PAYOUT_STATUSES['paid-out'].value:
        reason = intl.formatMessage({
          id: 'dashboard.transactions.payout.cannotPaused_tooltip.reason_paid_out',
        });
        pauseNotAppreciated = true;
        break;
      case PAYOUT_STATUSES['refunded'].value:
        reason = intl.formatMessage({
          id: 'dashboard.transactions.payout.cannotPaused_tooltip.reason_refunded',
        });
        pauseNotAppreciated = true;
        break;
      default:
        break;
    }

    return {
      pauseNotAppreciated: pauseNotAppreciated || penaltyFullyAppliedSale,
      popupTitle: intl.formatMessage({
        id: 'dashboard.transactions.payout.cannotPaused_tooltip.title',
      }),
      items: [
        intl.formatMessage({
          id: 'dashboard.transactions.payout.cannotPaused_tooltip.description',
        }),
        reason,
        penaltyFullyAppliedSale &&
          intl.formatMessage({
            id: 'dashboard.transactions.payout.cannotPaused_tooltip.reason_fully_applied',
          }),
      ],
    };
  };

  return (
    <div className="container-fluid top-block bg-white">
      <div className="details-header sale-details-header">
        <div className="row title-block pb-3 pb-lg-2">
          <div className="col-lg-6">
            {/*Sale title and statuses*/}
            <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-start pb-3">
              <Typography variant="h3" className="font-weight-bolder pe-lg-2">
                {intl.formatMessage({id: 'dashboard.sales.details.title'})} #
                {sale.referenceHexa}
              </Typography>
              <div className="d-flex">
                <StatusStateIndicator
                  status={getSaleStatus(sale)}
                  className="pe-2"
                />
                {transaction?.status !== 'paid' && (
                  <StatusStateIndicator status={transaction?.status} />
                )}
              </div>
            </div>
            {/*Order id / Creation date*/}
            <div className="order-values">
              <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                {/*Creation date*/}
                <div className="d-flex align-items-center">
                  <CalendarIcon style={{fontSize: 15, color: '#989898'}} />
                  <span className="text-muted ms-2">
                    <FormattedMessage id="dashboard.job_details.created" />
                    &nbsp;{dateHelper.format(sale.createdAt, 'DD/MM/YYYY')}
                  </span>
                </div>
                {/*IP*/}
                {sale?.creationIp && (
                  <PermissionContainer permissions={BUYER_IP_PERMISSIONS}>
                    <div className="d-flex align-items-center ps-3">
                      <WorldLocationIcon style={{fontSize: 18}} />
                      <span className="text-muted ms-2">
                        <FormattedMessage id="actions.from" /> IP:{' '}
                        {sale.creationIp}
                      </span>
                    </div>
                  </PermissionContainer>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <Box pt={0.5}>
              <div className="d-flex align-items-center justify-content-between justify-content-lg-end action-buttons pt-2 pt-lg-0">
                {/*Pause payout*/}
                {allowPausePayout && (
                  <Button
                    title={
                      <Typography
                        variant="body2"
                        component="span"
                        className="ms-1"
                      >
                        {intl.formatMessage({
                          id: 'dashboard.transactions.payout.pause',
                        })}
                      </Typography>
                    }
                    link
                    icon={<PauseIcon />}
                    onClick={onPausePayout}
                  />
                )}
                {/*Resume payout*/}
                {allowResumePayout && (
                  <Button
                    title={
                      <Typography
                        variant="body2"
                        component="span"
                        className="ms-1"
                      >
                        {intl.formatMessage({
                          id: 'dashboard.transactions.payout.resume',
                        })}
                      </Typography>
                    }
                    link
                    icon={<ResumeIcon />}
                    onClick={onResumePayout}
                  />
                )}
                {/* If the sale cannot be paused, the Question Mark icon is shown instead of the Pause icon. */}
                {checkPayoutCannotBePaused().pauseNotAppreciated && (
                  <HintTooltip
                    id={sale.id}
                    gray={false}
                    leftTop
                    maxWidth={240}
                    disabled
                    content={
                      <ListTooltip
                        header={checkPayoutCannotBePaused().popupTitle}
                        items={checkPayoutCannotBePaused().items}
                      />
                    }
                    icon={
                      <Button
                        title={
                          <Typography
                            variant="body2"
                            component="span"
                            className="ms-1"
                          >
                            {intl.formatMessage({
                              id: 'dashboard.transactions.payout.pause',
                            })}
                          </Typography>
                        }
                        link
                        icon={<PauseIcon disabled />}
                        disabled
                      />
                    }
                  />
                )}
                <div className="ms-auto ms-lg-3">
                  <Actions
                    setCancelPopup={setCancelPopup}
                    setAsCancelledPopup={setAsCancelledPopup}
                    setRefundPopup={setRefundPopup}
                    setPenaltyPopup={setPenaltyPopup}
                    setPaymentPopup={setPaymentPopup}
                    createPaymentEnabled={createPaymentEnabled}
                    setAsDeliveredPopup={setAsDeliveredPopup}
                    allowCancel={allowCancel}
                    allowAsCancelled={allowAsCancelled}
                    allowPenalty={allowPenalty}
                    allowRefund={allowRefund}
                    allowCreatePayment={allowCreatePayment}
                    allowAsDelivered={allowAsDelivered}
                    // allowPausePayout={allowPausePayout}
                    // allowResumePayout={allowResumePayout}
                    // onPausePayout={onPausePayout}
                    // onResumePayout={onResumePayout}
                  />
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

SaleDetailsHeader.propTypes = {
  sale: PropTypes.object.isRequired,
  custom: PropTypes.bool,
  setCustom: PropTypes.func,
  delivered: PropTypes.bool,
  setDelivered: PropTypes.func,
  shipped: PropTypes.bool,
  setShipped: PropTypes.func,
  showAlerts: PropTypes.func,
  setCancelPopup: PropTypes.func,
  setAsCancelledPopup: PropTypes.func,
  setRefundPopup: PropTypes.func,
  setPenaltyPopup: PropTypes.func,
  setPaymentPopup: PropTypes.func,
  setPayoutPopup: PropTypes.func,
  setDeliveredPopup: PropTypes.func,
};

export default SaleDetailsHeader;
