import {useDispatch, useSelector} from 'react-redux';
import {useCallback, useEffect} from 'react';
import {
  logout,
  selectToken,
  updateToken,
  updateUser,
} from '../store/slices/authSlice';
import {useLazyGetMeQuery} from '../services/authApi';
import {enqueueSnackbarError} from '../actions/snackbarActions';
import {chunk} from 'lodash';
import {IPermission, useLazyGetEventRolesQuery} from '../services/eventsApi';
import useProgressModal from '../hooks/app/useProgressModal';

/**
 * Fetch user when the app starts
 */
const FetchUser = () => {
  const token = useSelector(selectToken);
  const [getMe, {isError, isSuccess, data: me, error, isLoading: isGettingMe}] =
    useLazyGetMeQuery();
  const [getEventRoles, {isLoading: isGettingEventRoles}] =
    useLazyGetEventRolesQuery();
  const {show, hide} = useProgressModal();

  const dispatch = useDispatch();

  const getMeEventRoles = useCallback(async () => {
    if (!me) return;
    // chunk the roles to avoid a large query string and hitting the URL limit
    const meRoleSlugs = chunk<string>(me.roles, 10);
    const results = await Promise.all(
      meRoleSlugs.map(slugs => getEventRoles(slugs))
    );
    const meEventRolesPermissions = results
      .flatMap(result => result.data?.flatMap(role => role.permissions))
      .filter(Boolean) as IPermission[];
    dispatch(
      updateUser({
        user: {
          ...me,
          permissions: me.permissions.concat(
            meEventRolesPermissions.map(permission => permission.slug)
          ),
        },
      })
    );
  }, [me]);

  useEffect(() => {
    if (token) {
      getMe({token});
    }
  }, [token, getMe]);

  useEffect(() => {
    if (me && isSuccess) {
      // make sure me is an admin
      if (me.is_admin) {
        getMeEventRoles().catch(error => {
          console.log(error);
          dispatch(updateUser({user: me}));
        });
      } else {
        dispatch(
          enqueueSnackbarError({message: 'Please login with an admin account'})
        );
        dispatch(logout());
      }
    }
  }, [me, isSuccess, dispatch, getMeEventRoles]);

  useEffect(() => {
    if (isError) {
      // delete the token from Store as the user was not fetched
      dispatch(updateToken({token: null}));
    }
  }, [dispatch, error, isError]);

  useEffect(() => {
    if (isGettingEventRoles || isGettingMe) {
      show();
    } else {
      hide();
    }
  }, [isGettingEventRoles, isGettingMe]);

  return null;
};

export default FetchUser;
