import React from 'react';
import {makeStyles} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import {RadioProps} from '@material-ui/core/Radio/Radio';

const useStyles = makeStyles(theme => ({
  root: {},
  checked: {},
  icon: {
    width: 11,
    height: 11,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage:
      "url(\"data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' width='12px' height='12px' viewBox='0 0 12 12'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='SW---UI-style-guideline' transform='translate(-207.000000, -1128.000000)' fill='%23FFFFFF' stroke='%23B8B8B8'%3E%3Ccircle id='Oval-Copy-3' cx='213' cy='1134' r='5'%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")",
    'input:disabled ~ &': {
      boxShadow: 'none',
    },
  },
  checkedIcon: {
    width: 11,
    height: 12,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage:
      "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10px' height='11px' viewBox='0 0 10 11' fill='none'%3E%3Cpath d='M9.5455 5.50004C9.5455 8.01043 7.51043 10.0455 5.00004 10.0455C2.48966 10.0455 0.45459 8.01043 0.45459 5.50004C0.45459 2.98966 2.48966 0.95459 5.00004 0.95459C7.51043 0.95459 9.5455 2.98966 9.5455 5.50004Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5 1.40909C2.74065 1.40909 0.909091 3.24065 0.909091 5.5C0.909091 7.75935 2.74065 9.59091 5 9.59091C7.25935 9.59091 9.09091 7.75935 9.09091 5.5C9.09091 3.24065 7.25935 1.40909 5 1.40909ZM0 5.5C0 2.73858 2.23858 0.5 5 0.5C7.76142 0.5 10 2.73858 10 5.5C10 8.26142 7.76142 10.5 5 10.5C2.23858 10.5 0 8.26142 0 5.5Z' fill='%23FF7575'/%3E%3Cpath d='M7.72725 5.49998C7.72725 7.00621 6.50621 8.22725 4.99998 8.22725C3.49375 8.22725 2.27271 7.00621 2.27271 5.49998C2.27271 3.99375 3.49375 2.77271 4.99998 2.77271C6.50621 2.77271 7.72725 3.99375 7.72725 5.49998Z' fill='%23FF7575'/%3E%3C/svg%3E\")",
    'input:disabled ~ &': {
      filter: 'grayscale(1) opacity(0.5)',
    },
  },
}));

const CustomRadio = (props: RadioProps) => {
  const classes = useStyles();

  return (
    <Radio
      classes={{
        root: classes.root,
      }}
      icon={<span className={classes.icon} />}
      checkedIcon={<span className={classes.checkedIcon} />}
      {...props}
    />
  );
};

export default CustomRadio;
