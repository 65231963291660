import React, {useEffect} from 'react';
import {Fragment, useState} from 'react';
import * as PropTypes from 'prop-types';
import UserAddressItem, {UserNewAddressItem} from './Items/UserAddressItem';
import Modal from '../Widgets/Modal';
import AddressForm from './Form/AddressForm';
import useUserAddress from '../../hooks/user/useUserAddress';
import useDataTableStyles from '../Widgets/DataTable/useDataTableStyles';
import PermissionContainer from '../Common/PermissionContainer';
import {useParams} from 'react-router';
import * as actionTypes from '../../constants/actionTypes';
import {useDispatch} from 'react-redux';

/**
 * User Details Address tab content
 * @param props
 * @returns {*}
 * @constructor
 */
const AddressDetailsTab = props => {
  const dispatch = useDispatch();
  const [showUserAddressForm, setShowUserAddressForm] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const {user} = props;
  const {id} = useParams();
  //styles
  const classes = useDataTableStyles();

  //   User address hooks
  const {getUserAddress, addresses} = useUserAddress(id);

  // open edit modal and set default values
  const setAddress = address => {
    setSelectedAddress(address);
    setShowUserAddressForm(true);
  };

  // close modal and reset selected address
  const closeModal = () => {
    setSelectedAddress(null);
    setShowUserAddressForm(false);
  };

  // load user address
  useEffect(() => {
    getUserAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // extract Id from @id "/v2/addresses/:id"
  const extractId = stringValue => {
    const splitValue = stringValue.split('/');
    const id = splitValue[4];
    return id;
  };

  // handle delete User address
  const handleDelete = item => {
    const stringValue = item['@id'];
    const id = extractId(stringValue);
    // Dispatch action to Delete user address
    dispatch({
      type: actionTypes.API_USER_ADDRESS_DELETE_REQUESTED,
      payload: {id, ...item},
    });
  };

  // set address as default
  const handleSetAsDefaultConfirmation = item => {
    // Dispatch action to Set as Default
    const stringValue = item['@id'];
    const id = extractId(stringValue);
    const oldData = {...item};
    // set value to call api
    oldData['isDefault'] = true;
    // remove the value below when Abdelilah deals with the validation error
    oldData['firstname'] = user.firstname;
    oldData['lastname'] = user.lastname;
    const payload = {
      id,
      ...oldData,
    };
    dispatch({
      type: actionTypes.API_USER_ADDRESS_DEFAULT_REQUESTED,
      payload,
    });
  };

  return (
    <Fragment>
      <div className="container ms-lg-0">
        <div className="content-block">
          <div className="cards-list">
            <div className="row g-0 row-cols-1 row-cols-md-2 row-cols-xl-3">
              <PermissionContainer permissions={['admin_address_list']}>
                {addresses.map((item, index) => (
                  <div className="col" key={index}>
                    <UserAddressItem
                      address={item}
                      user={user}
                      onDelete={handleDelete}
                      onSetAsDefault={handleSetAsDefaultConfirmation}
                      setAddress={setAddress}
                    />
                  </div>
                ))}
              </PermissionContainer>
              {/*Add address*/}
              <PermissionContainer permissions={['admin_add_edit_address']}>
                <div className="col">
                  <UserNewAddressItem
                    onClick={() => setShowUserAddressForm(true)}
                  />
                </div>
              </PermissionContainer>
            </div>
          </div>
        </div>
      </div>
      {/*User details Address - Edit Popup*/}
      <Modal
        width={330}
        opened={showUserAddressForm}
        handleClose={() => setShowUserAddressForm(false)}
        emptyModal
      >
        <AddressForm
          closeModal={closeModal}
          address={selectedAddress}
          user={user}
          open={showUserAddressForm}
        />
      </Modal>
    </Fragment>
  );
};

AddressDetailsTab.propTypes = {
  user: PropTypes.object,
};

export default AddressDetailsTab;
