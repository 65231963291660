import * as actionTypes from '../constants/actionTypes';

const defaultState = {
  eventNames: [],
};

export default function eventReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.API_EVENT_AUTOCOMPLETE_REQUESTED:
      return {
        ...state,
        eventNames: [],
      };
    case actionTypes.API_EVENT_AUTOCOMPLETE_SUCCEEDED:
      return {
        ...state,
        eventNames: action.payload.eventNames,
      };
    default:
      return state;
  }
}
