import React from 'react';
import * as PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import clsx from 'clsx';

//Styles wrapper
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    overflow: 'hidden',
    '&.small': {
      '& h6': {
        fontSize: 12,
        paddingTop: 0,
      },
    },
  },
  label: {
    paddingLeft: 8,
    lineHeight: 1.2,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

//Status icon cancelled, failed, expired
const StatusIcon = ({color}) => (
  <SvgIcon
    viewBox="0 0 9 9"
    style={{
      fontSize: 9,
      marginTop: 1,
      color: color,
    }}
  >
    <g
      id="SW_Admin_User_Provider"
      transform="translate(-1181.000000, -372.000000)"
      fill="currentColor"
    >
      <g id="Group-2" transform="translate(1181.000000, 372.000000)">
        <circle
          id="Oval-Copy"
          fillOpacity="0.2"
          cx="4.5"
          cy="4.5"
          r="4.5"
        ></circle>
        <circle id="Oval-Copy" cx="4.5" cy="4.5" r="2.5"></circle>
      </g>
    </g>
  </SvgIcon>
);

/**
 * This component render information about record state with color by status prop
 * It can be used for Payment & Shipment entities of order
 * @param label
 * @param color - The color of Indicator, values: success, warning, secondary
 * @param className
 * @param other
 * @returns {*}
 * @constructor
 */
const StateIndicator = ({label, color, className, ...other}) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.root, className ?? className)}
      color={`${color}.main`}
      {...other}
    >
      <StatusIcon color={color} />
      <Typography
        variant="h6"
        className={clsx(classes.label, 'text-truncate')}
        style={{color: color}}
      >
        {label}
      </Typography>
    </Box>
  );
};

StateIndicator.propTypes = {
  /**
   * Status (cancelled, active, pending, suspended)
   */
  status: PropTypes.string,
  /**
   * Status text
   */
  label: PropTypes.string,
  /**
   * Additional class
   */
  className: PropTypes.string,
};

export default StateIndicator;
