import {useSelector, useDispatch} from 'react-redux';
// TODO: remove old logout handler (api, saga, etc)
import {
  selectToken,
  selectUser,
  logout as authLogout,
} from '../store/slices/authSlice';
import {useCallback, useEffect, useMemo} from 'react';
import {useLazyLogoutQuery} from '../services/authApi';

/**
 * React Hooks related to app auth :
 * - to get user, user token from store
 * - to fetch user with getMe() function
 * - logout using the logout() function
 * - loaded : User is loaded
 *
 * Returns an object containing the following keys: token, user, getMe, logout, loaded
 */
export default function useAuth() {
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const [logoutQuery, {isError, isSuccess}] = useLazyLogoutQuery();

  const userId = useMemo(() => user?.id, [user]);

  const dispatch = useDispatch();

  const logout = useCallback(() => {
    logoutQuery();
  }, [logoutQuery]);

  useEffect(() => {
    if (isError || isSuccess) {
      dispatch(authLogout());
    }
  }, [dispatch, isError, isSuccess, logout]);

  return {
    token,
    user,
    userId,
    logout,
  };
}
