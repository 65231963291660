import {call, cancelled, delay, put} from '@redux-saga/core/effects';
import axios from 'axios';
import {getList} from '../connectivity/functions';
import * as actionTypes from '../constants/actionTypes';
import * as urls from '../constants/urls';
import {addAuthToken, handleException} from './utils';
import {doEnqueueSnackBar} from './snackbar.saga';
import {appIntl} from '../translations/IntlGlobalProvider';
import {put as apiPut} from '../connectivity/api';
import {mergeSalePenaltyPayments} from '../utils/saleHelper';

export function* doGetPayoutList(action) {
  const cancelSource = axios.CancelToken.source();
  try {
    yield delay(500);
    const data = yield call(getList, {
      ...action.payload,
      cancelToken: cancelSource.token,
      url: urls.API_V2_PAYOUTS_URL,
    });

    yield put({
      type: actionTypes.API_TRANSACTION_PAYOUT_LIST_SUCCEEDED,
      payload: {
        ...action.payload,
        ...data,
        'hydra:member': (data['hydra:member'] ?? []).map(p => ({
          ...p,
          // merge adjustments
          salePenaltyPayments: mergeSalePenaltyPayments(p.salePenaltyPayments),
        })),
      },
    });
  } catch (error) {
    yield handleException({
      error,
      type: actionTypes.API_TRANSACTION_PAYOUT_LIST_FAILED,
    });
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}

/**
 * Resume a payout
 *
 * @param action
 */
export function* doResumePayout(action) {
  try {
    const {id, token} = yield addAuthToken(action.payload);
    // Call to API
    const data = yield apiPut({
      url: urls.API_V2_PAYOUT_RESUME_URL.replace(':id', id),
      ldjson: true,
      token,
      body: {},
    });

    // Send information to reducer
    yield put({
      type: actionTypes.API_ADMIN_RESUME_PAYOUT_SUCCEEDED,
      payload: {payout: data},
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({
        id: 'dashboard.sales.details.resume_payout_success_message',
      }),
      'success'
    );
  } catch (error) {
    yield handleException({
      error,
      type: actionTypes.API_ADMIN_RESUME_PAYOUT_FAILED,
    });
  }
}

/**
 * Pause a payout
 *
 * @param action
 */
export function* doPausePayout(action) {
  try {
    const {id, token} = yield addAuthToken(action.payload);
    // Call to API
    const data = yield apiPut({
      url: urls.API_V2_PAYOUT_PAUSE_URL.replace(':id', id),
      ldjson: true,
      token,
      body: {},
    });

    // Send information to reducer
    yield put({
      type: actionTypes.API_ADMIN_PAUSE_PAYOUT_SUCCEEDED,
      payload: {payout: data},
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({
        id: 'dashboard.sales.details.pause_payout_success_message',
      }),
      'success'
    );
  } catch (error) {
    yield handleException({
      error,
      type: actionTypes.API_ADMIN_PAUSE_PAYOUT_FAILED,
    });
  }
}

/**
 * Retry a payout that is failed
 *
 * @param action
 */
export function* doRetryPayout(action) {
  try {
    const {id, token} = yield addAuthToken(action.payload);
    // Call to API
    const data = yield apiPut({
      url: urls.API_V2_PAYOUT_RETRY_URL.replace(':id', id),
      ldjson: true,
      token,
      body: {},
    });

    // Send information to reducer
    yield put({
      type: actionTypes.API_ADMIN_RETRY_PAYOUT_SUCCEEDED,
      payload: {payout: data},
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({
        id: 'dashboard.sales.details.retry_payout_success_message',
      }),
      'success'
    );
  } catch (error) {
    yield handleException({
      error,
      type: actionTypes.API_ADMIN_RETRY_PAYOUT_FAILED,
    });
  }
}
