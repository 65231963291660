import React, {useState} from 'react';
import * as PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import {Box, Typography} from '@material-ui/core';
import {FormattedMessage} from 'react-intl';
import IconButton from '../../Common/IconButton';
import IndicatorDefault from '../../Common/IndicatorDefault';
import {EDIT_ICON, TRASH_ICON} from '../../../constants/images';
import PermissionContainer from '../../Common/PermissionContainer';
import ConfirmationPopup from '../../Sales/Details/Popups/ConfirmationPopup';
import {injectIntl} from 'react-intl';
import {APP_COUNTRY_STATES} from '../../../constants/countryStateList';

export const UserNewAddressItem = props => {
  return (
    <div className="card address-card add-new mt-0 mb-3">
      <div className="inner d-flex flex-column align-items-center" {...props}>
        <AddIcon style={{color: '#B8B8B8'}} />
        <Box pt={0.7}>
          <FormattedMessage id="dashboard.user_details.tab_address.add_address" />
        </Box>
      </div>
    </div>
  );
};

/**
 * Address item of address list
 */
let UserAddressItem = props => {
  const {address, onDelete, onSetAsDefault, intl, setAddress} = props;
  const {
    firstname,
    lastname,
    isDefault,
    lineOne,
    lineTwo,
    city,
    zipcode,
    state,
    country,
    phoneNumber,
  } = address;
  // Delete popup
  const [deletePopup, setDeletePopup] = useState(false);

  const openDeletePopup = () => setDeletePopup(true);

  const deleteAddressAction = () => {
    setDeletePopup(false);
    onDelete(address);
  };

  // show country's full name as short code is being saved
  const showCountryFullName = country => {
    const countryAddess = country === 'UK' ? 'GB' : country;
    const defaultCountry = APP_COUNTRY_STATES.find(
      where => where.countryShortCode === countryAddess
    );
    return defaultCountry.countryName;
  };

  return (
    <>
      {/* Delete User Address confirmation popup */}
      <ConfirmationPopup
        open={deletePopup}
        setOpen={setDeletePopup}
        onAccept={deleteAddressAction}
        description={intl.formatMessage({
          id: 'dashboard.user_details.actions.delete_user_address_message',
        })}
      />
      <div className="card address-card mt-0 mb-3">
        <div className="card-header">
          <IndicatorDefault
            isDefault={isDefault}
            onClick={() => onSetAsDefault(address)}
          />
          <div className="btn-toolbar">
            {/* Edit button */}
            <PermissionContainer permissions={['admin_add_edit_address']}>
              <IconButton
                size="xs"
                variant="action"
                onClick={() => setAddress(address)}
              >
                {EDIT_ICON}
              </IconButton>
            </PermissionContainer>
            {/* Delete button */}
            <IconButton size="xs" variant="action" onClick={openDeletePopup}>
              {TRASH_ICON}
            </IconButton>
          </div>
        </div>
        <div className="card-body">
          {/*Name*/}
          <div className="item-row">
            <Box pb={0.5}>
              <Typography variant="subtitle2" className="text-placeholder">
                <FormattedMessage id="dashboard.user_details.tab_address.name" />
              </Typography>
            </Box>
            <Typography variant="body2">
              {firstname} {lastname}
            </Typography>
          </div>
          {/*Address values*/}
          <div className="item-row">
            <Box pb={0.5}>
              <Typography variant="subtitle2" className="text-placeholder">
                <FormattedMessage id="dashboard.user_details.tab_address.address" />
              </Typography>
            </Box>
            {lineOne && <Typography variant="body2">{lineOne}</Typography>}
            {lineTwo && <Typography variant="body2">{lineTwo}</Typography>}
            {(city || zipcode) && (
              <Typography variant="body2">
                {city} {zipcode}
              </Typography>
            )}
            {state && <Typography variant="body2">{state}</Typography>}
            {country && (
              <Typography variant="body2">
                {showCountryFullName(country)}
              </Typography>
            )}
          </div>
          {/*Phone number*/}
          <div className="item-row pb-0">
            <Box pb={0.5}>
              <Typography variant="subtitle2" className="text-placeholder">
                <FormattedMessage id="dashboard.user_details.tab_address.phone_number" />
              </Typography>
            </Box>
            <Typography variant="body2">{phoneNumber || '-'}</Typography>
          </div>
        </div>
      </div>
    </>
  );
};

UserAddressItem.propTypes = {
  /**
   * @type {UserAddress}
   */
  address: PropTypes.object,
  user: PropTypes.object,
};

UserAddressItem.defaultProps = {
  address: {},
};

//Inject Intl translations into the component
UserAddressItem = injectIntl(UserAddressItem);

export default UserAddressItem;
