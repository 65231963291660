import React, {Fragment, useState} from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IndicatorDefault from '../../Common/IndicatorDefault';
import IconButton from '../../Common/IconButton';
import {TRASH_ICON} from '../../../constants/images';
import {FormattedMessage} from 'react-intl';
import ConfirmationPopup from '../../Sales/Details/Popups/ConfirmationPopup';
import {injectIntl} from 'react-intl';
import {APP_COUNTRY_STATES} from '../../../constants/countryStateList';

/* Bank item of the Banks list */
let BankListItem = props => {
  const {item, isNew = false, onSetAsDefault, onDelete, intl} = props;
  const {
    is_default = false,
    country,
    bank_name,
    routing_number,
    last4,
    currency,
  } = item;

  // open/close confirmation popup
  const [deletePopup, setDeletePopup] = useState(false);

  // open confirmation modal
  const openDeletePopup = () => setDeletePopup(true);

  // handle delete on yes
  const deleteBankAction = () => {
    setDeletePopup(false);
    onDelete(item);
  };

  // show country's full name as short code is being saved
  const showCountryFullName = country => {
    const countryAddess = country === 'UK' ? 'GB' : country;
    const defaultCountry = APP_COUNTRY_STATES.find(
      where => where.countryShortCode === countryAddess
    );
    return defaultCountry.countryName;
  };

  return (
    <Fragment>
      {/* Delete Confirmation popup */}
      <ConfirmationPopup
        open={deletePopup}
        setOpen={setDeletePopup}
        onAccept={deleteBankAction}
        description={intl.formatMessage({
          id: 'dashboard.user_details.actions.delete_user_bank_message',
        })}
      />

      <div className={clsx('card', isNew && 'add-new')}>
        <Fragment>
          <div className="card-header">
            <IndicatorDefault
              isDefault={is_default}
              onClick={() => onSetAsDefault(item)}
            />
            <div className="btn-toolbar">
              {/* Edit button */}
              {/* <IconButton
                size="xs"
                variant="action"
                onClick={() => openEditModal(item)}
              >
                {EDIT_ICON}
              </IconButton> */}
              {/* Delete button */}
              <IconButton size="xs" variant="action" onClick={openDeletePopup}>
                {TRASH_ICON}
              </IconButton>
            </div>
          </div>
          <div className="card-body">
            {/*Country*/}
            <div className="item-row">
              <Box pb={0.3}>
                <Typography variant="subtitle2" className="text-placeholder">
                  <FormattedMessage id="dashboard.user_details.tab_address.country" />
                </Typography>
              </Box>
              <Typography variant="body2">
                {country && showCountryFullName(country)}
              </Typography>
            </div>
            {/*Currency*/}
            <div className="item-row">
              <Box pb={0.3}>
                <Typography variant="subtitle2" className="text-placeholder">
                  <FormattedMessage id="dashboard.user_details.tab_address.country" />
                </Typography>
              </Box>
              <Typography variant="body2">
                {currency && currency.toUpperCase()}
              </Typography>
            </div>
            {/*Bank name*/}
            <div className="item-row">
              <Box pb={0.3}>
                <Typography variant="subtitle2" className="text-placeholder">
                  <FormattedMessage id="dashboard.user_details.tab_finance.bank_name" />
                </Typography>
              </Box>
              <Typography variant="body2">{bank_name}</Typography>
            </div>
            {/*Swift number: display for only US and UK*/}

            {country === 'US' || country === 'GB' ? (
              <div className="item-row">
                <Box pb={0.3}>
                  <Typography variant="subtitle2" className="text-placeholder">
                    {/* display ACH routing number for US and Sort Code for UK */}
                    <FormattedMessage
                      id={
                        country === 'US'
                          ? 'dashboard.user_details.tab_finance.routing_number'
                          : country === 'GB'
                            ? 'dashboard.user_details.tab_finance.sort_code'
                            : ''
                      }
                    />
                  </Typography>
                </Box>
                <Typography variant="body2">{routing_number}</Typography>
              </div>
            ) : (
              <div className="item-row">
                <Box pb={0.3}>
                  <Typography variant="subtitle2" className="text-placeholder">
                    {/* display ACH routing number for US and Sort Code for UK */}
                    <FormattedMessage
                      id={'dashboard.user_details.tab_finance.swift_number'}
                    />
                  </Typography>
                </Box>
                <Typography variant="body2"> - </Typography>
              </div>
            )}
            {/*IBAN number*/}
            <div className="item-row">
              <Box pb={0.3}>
                <Typography variant="subtitle2" className="text-placeholder">
                  <FormattedMessage
                    id={
                      country === 'US' || country === 'GB'
                        ? 'dashboard.user_details.tab_finance.account_number'
                        : 'dashboard.user_details.tab_finance.iban_number'
                    }
                  />
                </Typography>
              </Box>
              <Typography variant="body2">********{last4}</Typography>
            </div>
          </div>
        </Fragment>
      </div>
    </Fragment>
  );
};

//Inject Intl translations into the component
BankListItem = injectIntl(BankListItem);

export default BankListItem;
