import {IntlShape} from 'react-intl';
import Box from '@material-ui/core/Box';
import {IMG_BASE_URL} from '../../../../constants/urls';
import {
  EventManualIcon,
  ImportedEventIcon,
  UpdateIcon,
} from '../../../../constants/images';
import Typography from '@material-ui/core/Typography';
import ArrowLink from '../../../Common/ArrowLink';
import EventMark from '../../components/EventMark';
import {IChanges, IPerformer} from '../../../../services/eventsApi';

const iconStyles = {style: {width: 11, height: 11}};

export interface PerformerRowProps {
  image: string;
  title: string;
  isManual?: boolean;
  url: string;
  update?: IChanges<IPerformer>['name'];
  intl: IntlShape;
}

export function PerformerRow(props: PerformerRowProps) {
  const {image, title, isManual = false, url, update, intl} = props;
  return (
    <Box
      display="flex"
      alignItems="center"
      gridGap={10}
      className="overflow-hidden ps-2 ps-lg-1"
    >
      <picture className="event-picture">
        <img
          className="img-fluid rounded-sm"
          src={image ?? `${IMG_BASE_URL}event-placeholder.jpg`}
          alt=""
          style={{width: 35, height: 35}}
        />
      </picture>
      <Box
        display="flex"
        flexDirection="column"
        gridGap={5}
        className="overflow-hidden"
      >
        <Box
          display="flex"
          alignItems="center"
          gridGap={5}
          className="overflow-hidden"
        >
          <EventMark
            icon={isManual ? <EventManualIcon /> : <ImportedEventIcon />}
            formattedMessageId={
              'dashboard.events.list.' + (isManual ? 'manual_input' : 'import')
            }
            iconSize="11"
          />
          <Typography variant="body2" className="text-truncate">
            {title}
          </Typography>
        </Box>
        {update && (
          <Box
            display="flex"
            alignItems="center"
            gridGap={5}
            className="overflow-hidden"
          >
            <UpdateIcon {...iconStyles} />
            <p className="text-truncate mb-0">
              <Typography
                component="span"
                variant="body2"
                className="text-blue font-bold-italic"
              >
                {intl.formatMessage({
                  id: 'dashboard.performer.performer_update',
                })}
              </Typography>
              <Typography
                component="span"
                variant="body2"
                className="text-blue font-bold-italic"
              >
                {': '}
                {update.value}
              </Typography>
            </p>
          </Box>
        )}
        <ArrowLink gray url={url} title={url} targetBlank />
      </Box>
    </Box>
  );
}
