import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Nav, NavItem, NavLink, TabContent} from 'reactstrap';
import FinanceDetailsBanks from './Finance/FinanceDetailsBanks';
import FinanceDetailsFee from './Finance/FinanceDetailsFee';

/**
 * User Details Finance tab content
 * @param props
 * @returns {*}
 * @constructor
 */
const FinanceDetailsTab = props => {
  const {user} = props;
  //active tab
  const [activeTab, setActiveTab] = useState('fee');

  //switch active tab
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="container ms-lg-0">
      <div className="content-block">
        {/* Tabs (Menu) */}
        <Nav pills>
          {/*Finance Details - Fee*/}
          {user.stripeAccount && (
            <NavItem>
              <NavLink
                active={activeTab === 'fee'}
                onClick={() => toggle('fee')}
              >
                <FormattedMessage id="dashboard.user_details.tab_finance.fee" />
              </NavLink>
            </NavItem>
          )}
          {/* <NavItem>
                  <NavLink active={activeTab === 'cards'} onClick={() => toggle('cards')}>
                     <FormattedMessage id="dashboard.user_details.tab_finance.debit_credit_card"/>
                  </NavLink>
               </NavItem> */}
          {user.stripeAccount && (
            <NavItem>
              <NavLink
                active={activeTab === 'banks'}
                onClick={() => toggle('banks')}
              >
                <FormattedMessage id="dashboard.user_details.tab_finance.bank_details" />
              </NavLink>
            </NavItem>
          )}
        </Nav>

        <TabContent activeTab={activeTab}>
          {/*Finance Details - Fee*/}
          {user.stripeAccount && <FinanceDetailsFee user={user} />}
          {/*Finance Details - Cards*/}
          {/* <FinanceDetailsCards /> */}
          {/* Finance Details - Bank details */}
          {user.stripeAccount && <FinanceDetailsBanks user={user} />}
        </TabContent>
      </div>
    </div>
  );
};

export default FinanceDetailsTab;
