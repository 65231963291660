import React, {Fragment, ReactNode} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import {ButtonProps} from '@material-ui/core/Button/Button';
import {ITheme} from '../../constants/theme';

const useStyles = makeStyles((theme: ITheme) => ({
  root: (props: {mobileFullWidth: boolean; withIcon: boolean}) => ({
    justifyContent: 'flex-start',
    fontWeight: 'normal',
    [theme.breakpoints.down('md')]: {
      width: props.mobileFullWidth ? '100%' : 'auto',
      '& .MuiButton-label': {
        justifyContent: 'center !important',
      },
    },
    '&:hover:not(.no-underlined)': {
      textDecoration: 'underline',
    },
    '&.MuiButton-contained': {
      fontFamily: 'Averta Semibold',
      fontWeight: 'bold',
      borderRadius: 40,
      padding: '6px 20px 6px 20px',
      boxShadow: theme.shadows[1],
      minHeight: 40,
      minWidth: 97,
      '&:hover': {
        textDecoration: 'none',
      },
    },
    '&.MuiButton-outlined': {
      borderRadius: 20,
      fontSize: 14,
      lineHeight: '19px',
      padding: '10px 20px 10px 20px',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    '&.MuiButton-outlinedPrimary': {
      borderColor: theme.palette.primary.main,
    },
    '&.MuiButton-text': {
      padding: 0,
      backgroundColor: 'transparent !important',
      fontSize: 14,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& .MuiButton-label': {
        fontFamily: 'Averta Semibold',
      },
      '&.danger': {
        color: theme.palette.primary.main,
      },
      '&.MuiButton-textPrimary': {
        color: theme.palette.green.main,
      },
      '&.small': {
        '& .MuiButton-label': {
          fontFamily: 'Averta Regular',
        },
      },
    },
    '&.menu': {
      backgroundColor: 'transparent',
      boxShadow: theme.shadows[0],
      borderRadius: 0,
      paddingTop: 19,
      paddingBottom: 0,
      paddingLeft: 5,
      '& .MuiButton-label': {
        width: 'auto',
        paddingLeft: 20,
        flexDirection: 'column',
        height: 25,
      },
      '& div': {
        width: 17,
        height: 2,
        backgroundColor: theme.palette.text.primary,
        marginTop: 2,
        marginBottom: 2,
      },
    },
    '&.danger': {
      backgroundColor: theme.palette.danger.main,
      '&:hover': {
        backgroundColor: theme.palette.danger.hover,
      },
    },
    '&.light': {
      color: theme.palette.secondary.light,
      fontWeight: 'normal',
    },
    '&.secondary': {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.gray.light,
      boxShadow: theme.shadows[0],
    },
    '&.green': {
      backgroundColor: theme.palette.green.main,
    },
    '&.action': {
      [theme.breakpoints.up('lg')]: {
        minWidth: 150,
      },
    },
    '&.toolbar': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.default,
      boxShadow: 'none',
      borderRadius: 4,
      minHeight: 30,
      padding: '6px 10px',
      overflow: 'hidden',
      '& .MuiButton-label': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        fontSize: 12,
        fontFamily: 'Averta Semibold',
        '& .MuiButton-startIcon': {
          marginRight: 7,
        },
      },
      '&:hover, &.active': {
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.text.primary,

        '& .MuiButton-label': {
          color: theme.palette.background.paper,
        },
      },
    },
    '&.small': {
      minWidth: 'unset',
      '& .MuiButton-label': {
        fontSize: 12,
        lineHeight: 1.25,
        fontFamily: 'Averta Semibold',
      },
    },
    '& .MuiButton-label': {
      alignItems: 'center',
      justifyContent: !props.withIcon ? 'center' : 'inherit',
    },
    '&:focus': {
      outline: 'none',
    },
  }),
  startIcon: {
    margin: '0 10px 0 0',
    maxHeight: 27,
  },
}));

type CustomButtonProps = ButtonProps & {
  primary?: boolean;
  danger?: boolean;
  secondary?: boolean;
  outlined?: boolean;
  mobileFullWidth?: boolean;
  link?: boolean;
  toolbar?: boolean;
  small?: boolean;
  icon?: ReactNode;
  menu?: boolean;
  light?: boolean;
  title?: ReactNode;
};

const CustomButton = (props: CustomButtonProps) => {
  const {
    primary,
    danger,
    secondary,
    outlined = false,
    mobileFullWidth = false,
    link,
    toolbar,
    small,
    icon = null,
    menu,
    light,
    title,
    disabled = false,
    ...other
  } = props;
  const notLinkColor = !link ? 'secondary' : 'default';
  const linkVariant = link ? 'text' : 'contained';
  const buttonColor = primary ? 'primary' : notLinkColor;
  const buttonVariant = outlined ? 'outlined' : linkVariant;

  const classes = useStyles({mobileFullWidth, withIcon: !!icon});

  return (
    <Button
      color={buttonColor}
      variant={buttonVariant}
      disabled={disabled}
      classes={{
        root: clsx(classes.root, menu && 'menu', icon && 'no-underlined'),
        startIcon: classes.startIcon,
      }}
      className={clsx({secondary, danger, toolbar, light, small})}
      startIcon={icon}
      {...other}
    >
      {menu ? (
        <Fragment>
          <div></div>
          <div></div>
          <div></div>
        </Fragment>
      ) : (
        title
      )}
    </Button>
  );
};

export default CustomButton;
