import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import {injectIntl} from 'react-intl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {useHistory, useLocation} from 'react-router-dom';
import {Column, Table as VirtualTable, WindowScroller} from 'react-virtualized';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import Checkbox from '../Common/Checkbox';
import IconButton from '../Common/IconButton';
import ProgressBar from '../Common/ProgressBar';
import {useMediaQuery} from 'react-responsive';
import {TABLE_DEFAULT_BREAKPOINT} from '../../constants/appConstants';
import theme from '../../constants/theme';
import get from 'lodash.get';
import {
  DownloadCompletedIcon,
  PauseGrayTableIcon,
  RetryFilledIcon,
} from '../../constants/images';
import DataTableHead from './DataTable/DataTableHead';
import useDataTableStyles from './DataTable/useDataTableStyles';
import BulkActions from './DataTable/BulkActions';
import TablePagination from './DataTable/TablePagination';
import useQueryParams from '../../hooks/useQueryParams';
import {TableSortLabel} from '@material-ui/core';
import WrappedRowCell from './DataTable/WrappedRowCell';
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion';
import HintTooltip from '../Common/HintTooltip';
import ListTooltip from './ListTooltip';

/**
 * Data Table (main component)
 * @param props
 * @returns {*}
 * @constructor
 */
const DataTable = props => {
  //styles
  const classes = useDataTableStyles();
  //rendering data props
  const {
    intl,
    empty,
    loading,
    wrappedRow,
    additionalContent,
    renderSubRows,
    canSelect,
    canPauseStart,
    canRetry,
    bulkActions,
    bulkProps,
    className,
    rows,
    uploadRows,
    currentPage,
    total,
    onChange,
    defaultOrder,
    rowPerPageOptions,
    defaultOrderBy,
    defaultRowPerPage,
    selectedItems,
    onSelectedItemsChange,
    renderBulkActions,
    isProcessingBulk,
    isSelectedFn,
    onSelect,
    onSelectAll,
    onSelectAllList,
    onClearSelect,
    showBulkActions,
    selectedCount,
    clickableRow,
    onRowClick,
    onCustomRowClick,
    onPauseStartClick,
    onRetryClick,
    handleParameterChange,
    onPaginationChange,
  } = props;
  const location = useLocation();
  const history = useHistory();
  const params = useQueryParams();

  const pageParam = Number(params.get('page'));
  const rowsPerPageParam = Number(params.get('rowsPerPage'));

  //order (asc/desc)
  const [order, setOrder] = useState(defaultOrder ?? 'asc');
  //order by column
  const [orderBy, setOrderBy] = useState(defaultOrderBy ?? '');
  //array of selected rows
  const [selected, setSelected] = useState([]);
  //current page shown
  const [page, setPage] = useState(pageParam || currentPage || 1);
  //number of rows shown in one page
  const [rowsPerPage, setRowsPerPage] = useState(
    rowsPerPageParam || defaultRowPerPage || 100
  );
  //collapse is opened (mobile view)
  const [openedRow, setOpenedRow] = useState(null);
  //when bulk actions in progress we could see all content covered opacity
  const [bulkOpacity, setBulkOpacity] = useState(false);
  const isMobile = useMediaQuery({maxWidth: props.mobileBreakPoint});
  //wrapped row the should be shown like expanded
  const [expandedWrappedRow, setExpandedWrappedRow] = useState(null);
  // for virtualized table only
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandInitiated, setExpandInitiated] = useState(false);

  //Sort by column click handler
  const handleRequestSort = useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      handleParameterChange &&
        handleParameterChange('order', {
          order: isAsc ? 'DESC' : 'ASC',
          orderBy: property,
        });
      // reset page on sort change
      setPage(1);
    },
    [order, orderBy]
  );

  //Select All checkbox click handler
  const handleSelectAllClick = event => {
    if (onSelectAll) {
      onSelectAll(rows);
    } else {
      //select all rows
      if (event.target.checked) {
        const newSelecteds = rows.map(n => n.id);
        setSelected(newSelecteds);
        onSelectedItemsChange && onSelectedItemsChange(newSelecteds);
        return;
      }
      //unselect all rows
      // setSelected([]);
      // onSelectedItemsChange && onSelectedItemsChange([]);
    }
  };

  // Update page when currentPage is changed from outside
  useEffect(() => setPage(currentPage), [currentPage]);

  useEffect(() => {
    if (defaultRowPerPage && defaultRowPerPage !== rowsPerPage) {
      setRowsPerPage(defaultRowPerPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultRowPerPage]);

  useEffect(() => {
    if (defaultOrder && defaultOrder !== order) {
      setOrder(defaultOrder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOrder]);

  useEffect(() => {
    if (defaultOrderBy && defaultOrderBy !== orderBy) {
      setOrderBy(defaultOrderBy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOrderBy]);

  const useHeadCells = (cells, isMobile) => {
    //Header cells
    const [headCells, setHeadCells] = useState(props.headCells);

    //Update header cells ordering
    const handleWindowResize = () => {
      //Mobile ?
      if (isMobile) {
        const newOrdered = cells.filter(item => !item.mobileFirst);
        const mobileFirst = cells.find(item => item.mobileFirst);
        if (mobileFirst) {
          newOrdered.unshift(mobileFirst);
        }
        if (newOrdered[0] && newOrdered[1]) {
          newOrdered[0].disablePadding = true;
          newOrdered[1].disablePadding = false;
        }
        setHeadCells(newOrdered);
      } else {
        setHeadCells(cells);
      }
    };

    useEffect(() => {
      handleWindowResize();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cells, isMobile]);

    return {headCells};
  };

  //Head cells
  const {headCells} = useHeadCells(props.headCells, isMobile);

  //Change page
  const handleChangePage = useCallback(
    newPage => {
      handleParameterChange && handleParameterChange('page', {page: newPage});
      if (page !== Number(newPage)) {
        setPage(Number(newPage));
      }
    },
    [page]
  );

  //Change rows per page select
  const handleChangeRowsPerPage = useCallback(
    value => {
      handleParameterChange &&
        handleParameterChange('pageSize', {pageSize: value});
      if (rowsPerPage !== Number(value)) {
        if (page !== 1) {
          setPage(1);
        }
        setRowsPerPage(Number(value));
      }
    },
    [page, rowsPerPage]
  );

  //Clear selection
  const clearSelection = () => {
    setSelected([]);
    onClearSelect && onClearSelect();
  };

  //select all items
  const selectAll = () => {};

  // Call onChange when page, rowsPerPage, order and orderBy changed
  useEffect(() => {
    if (onChange) {
      onChange({
        sort: {
          by: orderBy,
          direction: order,
        },
        page,
        rowsPerPage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, order, orderBy]);

  useEffect(() => {
    const pageFromUrl = Number(params.get('page')) || 1;
    if (page !== pageFromUrl) {
      setPage(pageFromUrl);
    }
  }, [params]);

  //Update query params
  // Remove or modify this effect to prevent the loop
  useEffect(() => {
    if (onPaginationChange) {
      onPaginationChange({page, rowsPerPage});
      return;
    }

    const existingParams = {} as Record<string, string>;
    for (const param of Array.from(params.entries())) {
      existingParams[param[0]] = param[1];
    }

    // Only update URL if the page has actually changed
    if (String(page) !== existingParams['page']) {
      existingParams['page'] = String(page);
      existingParams['rowsPerPage'] = String(rowsPerPage);
      const searchParams = new URLSearchParams(existingParams);

      history.push({
        pathname: location.pathname,
        search: '?' + searchParams.toString(),
      });
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (selectedItems !== undefined) {
      setSelected(selectedItems);
    }
  }, [selectedItems]);

  useEffect(() => {
    if (isProcessingBulk !== undefined) {
      setBulkOpacity(isProcessingBulk);
    }
  }, [isProcessingBulk]);

  // close wrapped row on rows (data) change
  useEffect(() => {
    setExpandedWrappedRow(null);
    setExpandedRows([]);
  }, [rows]);

  //Toggle state of expanded row
  const toggleWrappedRow = useCallback(
    rowIndex => {
      if (!expandInitiated) {
        setExpandInitiated(true);
      }
      const index = expandedRows.indexOf(rowIndex);
      const res = [...expandedRows];
      if (index === -1) {
        res.push(rowIndex);
      } else {
        res.splice(index, 1);
      }
      setExpandedRows(res);
      if (expandedWrappedRow === rowIndex) {
        setExpandedWrappedRow(null);
      } else {
        setExpandedWrappedRow(rowIndex);
      }
    },
    [expandInitiated, expandedWrappedRow, expandedRows]
  );

  //Collapse/expand row click handler
  const handleCollapseClick = useCallback(
    id => evt => {
      evt.preventDefault();
      evt.stopPropagation();

      //already expanded
      if (openedRow === id) {
        setOpenedRow(null);
        //expand another row
      } else {
        setOpenedRow(id);
      }
    },
    [openedRow]
  );

  //Row click handler
  const handleRowClick = useCallback(
    row => e => {
      const rowId = row.id;
      if (!clickableRow) return;
      //Check the target of clicked element,
      //if it's a link then we shouldn't make the redirect
      if (e.target && e.target.tagName === 'A') {
        return true;
      }
      if (
        e.target &&
        Array.isArray(e.target.classNames) &&
        e.target.classNames.indexOf('dropdown') !== -1
      ) {
        return true;
      }
      //run parent's callback
      if (typeof onRowClick === 'function') {
        onRowClick(rowId, e);
        return true;
      }
      //run custom callback
      if (typeof onCustomRowClick === 'function') {
        onCustomRowClick(row, e);
        return true;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  /**
   * Row click handler
   * @param event
   * @param row
   */
  const handleClick = useCallback(
    row => event => {
      //prevented expand/collapse on mobile view
      event.preventDefault();
      event.stopPropagation();

      if (onSelect) {
        onSelect(row);
      } else {
        const id = row.id;
        //find current row in selected items
        const selectedIndex = selected.indexOf(id);
        //result
        let newSelected = [];

        //not found
        if (selectedIndex === -1) {
          //add new selected
          newSelected = newSelected.concat(selected, id);
          //found the selected
        } else if (selectedIndex === 0) {
          //in the beginning
          newSelected = newSelected.concat(selected.slice(1));
          //in the end
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
          //in the middle
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1)
          );
        }
        //update list of selected items
        setSelected(newSelected);
        onSelectedItemsChange && onSelectedItemsChange(newSelected);
      }
    },
    [onSelect, onSelectedItemsChange, selected]
  );

  const renderTableCellValue = useCallback(
    ({
      key,
      itemUploaded,
      isItemSelected,
      row,
      name,
      wrap,
      renderValue,
      valueProperty,
    }) => (
      <>
        {typeof renderValue === 'function' ? (
          renderValue(get(row, valueProperty ?? name), '', {
            row,
            rows,
            uploadRows,
          })
        ) : //row is selected but not all rows are selected - PRIMARY color
        wrap ? (
          <Typography
            variant="body2"
            color={
              isItemSelected && key === 0 && openedRow === row.id
                ? 'primary'
                : 'textPrimary'
            }
          >
            {get(row, valueProperty ?? name)}
          </Typography>
        ) : (
          get(row, valueProperty ?? name)
        )}
        {/* If it is uploaded item - show download completed icon */}
        {!!itemUploaded && key === 0 && itemUploaded.level === 100 && (
          <DownloadCompletedIcon
            style={{fontSize: 15, margin: '-10px 0 0 10px'}}
          />
        )}
      </>
    ),
    [openedRow, rows, uploadRows]
  );

  //Table Cell rendering procedure
  const renderTableCell = useCallback(
    (
      {
        name,
        disablePadding,
        numeric,
        renderValue,
        collapse,
        mobileVisibleOnly,
        colSpan = 1,
        wrap = true,
        valueProperty,
      },
      key,
      row,
      labelId,
      isItemSelected,
      itemUploaded,
      className = '',
      tableCellProps = {}
    ) => (
      <TableCell
        key={key}
        className={clsx(
          {
            'd-none d-lg-table-cell': collapse && !mobileVisibleOnly,
            //collapsing part is opened
            bold: openedRow === row.id && key === 0,
            'marked-completed':
              !!itemUploaded && key === 0 && itemUploaded.level === 100,
            'py-0': row.hasBorderLeft,
          },
          classes.tableCell,
          className
        )}
        component="td"
        id={labelId}
        scope="row"
        padding={disablePadding ? 'none' : null}
        //alignment
        align={numeric ? 'right' : 'left'}
        style={{borderLeftColor: theme.palette[row?.borderLeftColor]?.main}}
        colSpan={colSpan}
        {...tableCellProps}
      >
        {renderTableCellValue({
          key,
          itemUploaded,
          isItemSelected,
          row,
          name,
          wrap,
          renderValue,
          valueProperty,
        })}
      </TableCell>
    ),
    [classes.tableCell, openedRow, renderTableCellValue]
  );

  const onClickWrappedRow = useCallback(
    index => e => {
      e.preventDefault();
      e.stopPropagation();
      toggleWrappedRow(index);
    },
    [toggleWrappedRow]
  );

  const handlePauseStartClick = useCallback(
    (event, row, index) => {
      event.stopPropagation();
      onPauseStartClick && onPauseStartClick(row, index, event);
    },
    [onPauseStartClick]
  );

  const handleRetryClick = useCallback(
    (event, row, index) => {
      event.stopPropagation();
      onRetryClick && onRetryClick(row, index, event);
    },
    [onRetryClick]
  );

  const getPauseButton = (row, index): JSX.Element | null => {
    if (onPauseStartClick && canPauseStart) {
      if (row.isPaused || row.canBePaused) {
        return (
          <IconButton
            variant="filled"
            size="xs"
            className="dt-btn"
            onClick={e => handlePauseStartClick(e, row, index)}
          >
            {row.isPaused ? (
              <PlayCircleFilledIcon style={{fontSize: 20}} />
            ) : (
              <PauseGrayTableIcon style={{fontSize: 20}} />
            )}
          </IconButton>
        );
      }

      return (
        <HintTooltip
          id={row.rowId}
          disabled
          gray={false}
          leftTop
          maxWidth={240}
          content={
            <ListTooltip
              header={row.pauseForbiddenReasons.title}
              items={row.pauseForbiddenReasons.items}
            />
          }
          icon={
            <IconButton
              variant="filled"
              size="xs"
              className="dt-btn disabled cursor-default"
            >
              <PauseGrayTableIcon disabled style={{fontSize: 20}} />
            </IconButton>
          }
        />
      );
    }

    return null;
  };

  const getWrappedRowContent = useCallback(
    ({
      row,
      rowIndex,
      cellProps = {},
      resolveIndex = index => index,
      wrap = true,
    }) => {
      const index = resolveIndex(rowIndex);
      const isFailed = row.status && row.status.value === 'failed';

      const content = (row.rowIsExpandable ||
        row.isPaused ||
        row.canBePaused) && (
        <div className="d-flex align-items-center gap-2">
          {row.rowIsExpandable && (
            <IconButton
              variant="action"
              size="xs"
              onClick={onClickWrappedRow(index)}
            >
              {expandedRows.includes(index) ? (
                <ExpandLess className={classes.expandIcon} />
              ) : (
                <ExpandMore className={classes.expandIcon} />
              )}
            </IconButton>
          )}
          {getPauseButton(row, index)}
          {onRetryClick && canRetry && isFailed && (
            <IconButton
              variant="filled"
              size="xs"
              className="dt-btn"
              onClick={e => handleRetryClick(e, row, index)}
            >
              <RetryFilledIcon style={{fontSize: 20}} />
            </IconButton>
          )}
        </div>
      );

      if (!wrap) {
        return content;
      }

      const classNames = 'd-none d-lg-table-cell text-center with-wrapped-row';

      return (
        <TableCell
          {...cellProps}
          className={clsx(classes.tableCell, classNames, {
            expanded: expandedRows.includes(index),
          })}
        >
          {content}
        </TableCell>
      );
    },
    [
      canPauseStart,
      classes.expandIcon,
      classes.tableCell,
      expandedWrappedRow,
      handlePauseStartClick,
      onClickWrappedRow,
      expandedRows,
    ]
  );

  const renderRow = useCallback(
    (row, index) => {
      //Check if row is selected now
      const isSelected = row =>
        isSelectedFn ? isSelectedFn(row) : selected.indexOf(row.id) !== -1;

      //row is checked
      const isItemSelected = isSelected(row);
      //row id
      const labelId = `data-table-checkbox-${index}`;
      //row is uploading
      const itemUploaded = uploadRows.find(item => item.id === row.id);

      return (
        <Fragment key={row.id + '-' + index}>
          <TableRow
            className={clsx(
              classes.tableRow,
              !!itemUploaded && 'hidden-last-cells',
              !onRowClick && 'no-clickable-row',
              itemUploaded && itemUploaded.status,
              row.hasBorderLeft && 'border-left'
            )}
            role={canSelect ? 'checkbox' : ''}
            aria-checked={isItemSelected}
            tabIndex={-1}
            selected={isItemSelected}
            onClick={handleRowClick(row)}
            data-testid={`data-table-row-${row.id}`}
          >
            {canSelect && (
              <TableCell
                className={clsx(
                  classes.tableCell,
                  openedRow === row.id && 'with-expanded-next-row'
                )}
                padding="checkbox"
                onClick={handleClick(row)}
              >
                <Checkbox
                  checked={isItemSelected}
                  inputProps={{'aria-labelledby': labelId}}
                />
              </TableCell>
            )}
            {/* On mobile view show not collapsed fields */}
            {headCells
              .filter(item => !item.wrapped)
              .map((item, key) =>
                renderTableCell(
                  item,
                  key,
                  row,
                  labelId,
                  isItemSelected,
                  itemUploaded,
                  clsx(
                    {'with-wrapped-row': wrappedRow},
                    {expanded: expandedWrappedRow === index}
                  )
                )
              )}
            {/* Expand/Collapse icon button for wrappedRow table */}
            {wrappedRow && getWrappedRowContent({row, rowIndex: index})}
            {/* Expand/Collapse icon button (mobile only) */}
            <TableCell
              align="right"
              className={clsx(
                'd-table-cell d-lg-none ps-0',
                classes.tableCell,
                openedRow === row.id && 'with-expanded-next-row'
              )}
              style={{width: '1%', cursor: 'pointer'}}
              onClick={handleCollapseClick(row.id)}
            >
              <Box pl={0.5}>
                {openedRow === row.id ? (
                  <ExpandLess className={classes.expandIcon} />
                ) : (
                  <ExpandMore className={classes.expandIcon} />
                )}
              </Box>
            </TableCell>
          </TableRow>
          {/*wrapped row*/}
          {expandedRows.includes(index) ? (
            additionalContent ? (
              <Fragment>
                <TableRow
                  className={clsx(classes.wrappedRow)}
                  onClick={handleRowClick(row)}
                >
                  <TableCell
                    colSpan={headCells.length + 1}
                    className={clsx(classes.tableCell, 'pt-0')}
                  >
                    <div className="sub-table">
                      {renderSubRows(row.subrows)}
                    </div>
                  </TableCell>
                </TableRow>
              </Fragment>
            ) : (
              <Fragment>
                <TableRow
                  className={clsx(classes.wrappedRow, 'headers')}
                  onClick={handleRowClick(row)}
                >
                  {headCells
                    .filter(item => item.wrapped)
                    .map((item, key) => (
                      <TableCell
                        className={clsx(
                          classes.tableCell,
                          'wrapped-row-cell headers'
                        )}
                        colSpan={
                          item.colspan
                            ? item.colspan
                            : key ===
                                headCells.filter(item => item.wrapped).length -
                                  1
                              ? 2
                              : 1
                        }
                      >
                        <Typography variant="body2" color="textSecondary">
                          {item.label}
                        </Typography>
                      </TableCell>
                    ))}
                </TableRow>
                <TableRow
                  className={clsx(
                    classes.tableRow,
                    classes.wrappedRow,
                    'cells'
                  )}
                  onClick={handleRowClick(row)}
                >
                  {headCells
                    .filter(item => item.wrapped)
                    .map((item, key) =>
                      renderTableCell(
                        {
                          ...item,
                          colSpan: item.colspan
                            ? item.colspan
                            : key ===
                                headCells.filter(item => item.wrapped).length -
                                  1
                              ? 2
                              : 1,
                        },
                        key,
                        row,
                        labelId,
                        isItemSelected,
                        itemUploaded,
                        'wrapped-row-cell cells'
                      )
                    )}
                </TableRow>
                <TableRow className={clsx(classes.wrappedRow, 'additional')}>
                  <TableCell
                    colSpan={headCells.filter(item => item.wrapped).length}
                  />
                </TableRow>
              </Fragment>
            )
          ) : null}
          {!!itemUploaded && (
            <tr>
              <td
                colSpan={headCells.length + 1}
                className={clsx(
                  classes.progressBarWrapper,
                  itemUploaded.status
                )}
              >
                <ProgressBar value={itemUploaded.level} />
              </td>
            </tr>
          )}
          <TableRow className={row.hasBorderLeft && 'border-left collapsable'}>
            <TableCell
              className={classes.collapsableTableCell}
              colSpan={headCells.filter(item => !item.collapse).length + 2}
              style={{
                borderLeftColor: theme.palette[row?.borderLeftColor]?.main,
              }}
            >
              <Collapse
                in={openedRow === row.id}
                className={clsx(classes.collapseWrapper, 'd-lg-none')}
                unmountOnExit
              >
                {/* Render collapsed fields (on mobile view) */}
                {headCells.map(
                  ({name, label, renderValue, collapse, mobileFirst}, key) =>
                    collapse &&
                    !mobileFirst && (
                      <div
                        key={key}
                        className={clsx(
                          classes.collapsableRow,
                          !canSelect && 'ps-3'
                        )}
                      >
                        <div className={classes.collapsableRowItem}>
                          <Typography variant="subtitle1">
                            <b>{label}</b>
                          </Typography>
                        </div>
                        <div className={classes.collapsableRowItem}>
                          {typeof renderValue === 'function' ? (
                            renderValue(get(row, name), '', {
                              row,
                              rows,
                              uploadRows,
                            })
                          ) : (
                            <Typography variant="subtitle1">
                              {get(row, name)}
                            </Typography>
                          )}
                        </div>
                      </div>
                    )
                )}
              </Collapse>
            </TableCell>
          </TableRow>
        </Fragment>
      );
    },
    [
      additionalContent,
      canSelect,
      classes.collapsableRow,
      classes.collapsableRowItem,
      classes.collapsableTableCell,
      classes.collapseWrapper,
      classes.expandIcon,
      classes.progressBarWrapper,
      classes.tableCell,
      classes.tableRow,
      classes.wrappedRow,
      expandedWrappedRow,
      handleClick,
      handleCollapseClick,
      handleRowClick,
      headCells,
      isSelectedFn,
      onRowClick,
      openedRow,
      renderSubRows,
      renderTableCell,
      rows,
      selected,
      uploadRows,
      wrappedRow,
      getWrappedRowContent,
      expandedRows,
    ]
  );

  const headerCellItems = useMemo(() => {
    return !wrappedRow ? headCells : headCells.filter(item => !item.wrapped);
  }, [wrappedRow, headCells]);

  const useVirtualizedTable = useMemo(() => {
    // every column has a virtualWidth or is an extraColumn (added dynamically)
    const wOK =
      (headerCellItems ?? []).length > 0 &&
      headerCellItems.filter(item => !item.virtualWidth && !item.extraColumn)
        .length === 0;

    return wOK && !isMobile;
  }, [headerCellItems, isMobile]);

  const renderedRows = useMemo(() => {
    if (useVirtualizedTable) {
      return null;
    }
    return rows.map(renderRow);
  }, [renderRow, rows, useVirtualizedTable]);

  const animationVariants = {
    visible: {opacity: 1},
    exit: {opacity: 0},
    hidden: {opacity: 0},
  };

  const additonalColumnWidth = useMemo(() => 115, []);

  const virtualHeaderCellItems = useMemo(() => {
    const items = [...headerCellItems];

    // add column for expand/collapse or pause/start
    if (wrappedRow || canPauseStart) {
      items.push({
        label: '',
        extraColumn: true, // will be used to identify additional column
        position: 'last', // will be used for rendering
      });
    }

    return items;
  }, [canPauseStart, headerCellItems, wrappedRow]);

  const virtualRows = useMemo(() => {
    // add vWrappedIndex in vrow for performance reason
    // const vrows = [...rows].map((r, i) => ({ ...r, vWrappedIndex: i }));
    const vrows = [];
    (rows ?? []).forEach((r, i) => {
      vrows.push({...r, vWrappedIndex: i});
      if (expandedRows.includes(i)) {
        const height =
          8 +
          props.virtualWrappedRowAdditionalHeight +
          (r.subrows ?? []).length * props.virtualWrappedRowHeight;
        vrows.push({
          wrappedRow: true,
          realRowIndex: i,
          height,
        });
      }
    });

    return vrows;
  }, [
    rows,
    expandedRows,
    props.virtualWrappedRowAdditionalHeight,
    props.virtualWrappedRowHeight,
  ]);

  const rowCount = useMemo(() => (rows ?? []).length, [rows]);

  const virtualHeaderRenderer = useCallback(
    ({columnIndex, ...colDef}) => {
      const {
        name,
        collapse,
        disablePadding,
        label,
        numeric,
        sortable = true,
        headerClassName,
      } = colDef;
      const numSelected = selectedCount ? selectedCount : selected.length;
      const index = columnIndex;

      if (colDef.extraColumn) {
        return (
          <TableCell
            align="right"
            className={clsx(
              classes.headCell,
              'd-table-cell',
              {'d-lg-none': !wrappedRow},
              'action-wrapper'
            )}
            style={{width: additonalColumnWidth}}
          >
            {props.actionButton ? (
              <IconButton {...props.actionButton} className="m-0 ml-auto">
                {props.actionButton.children}
              </IconButton>
            ) : null}
          </TableCell>
        );
      }

      return (
        <TableCell
          component="div"
          variant="head"
          style={{height: '48px'}}
          key={name}
          className={clsx(
            collapse ? 'd-none d-lg-table-cell' : 'd-table-cell text-nowrap',
            'overflow-hidden',
            classes.headCell,
            !canSelect && 'pb-lg-3',
            headerClassName
          )}
          align={numeric ? 'right' : 'left'}
          padding={disablePadding ? 'none' : null}
          sortDirection={orderBy === name ? order : false}
        >
          {sortable ? (
            <TableSortLabel
              className={clsx(
                'd-block text-truncate',
                numSelected < rowCount && 'color-disabled',
                !canSelect && index === 0 && 'ps-lg-1 '
              )}
              active={orderBy === name}
              direction={orderBy === name ? order : 'asc'}
              onClick={event => handleRequestSort(event, name)}
            >
              {label}
              {/* Ordered by this column ? */}
              {orderBy === name && (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              )}
            </TableSortLabel>
          ) : (
            <TableSortLabel
              className={clsx({'color-disabled': numSelected < rowCount})}
            >
              {label}
            </TableSortLabel>
          )}
        </TableCell>
      );
    },
    [
      additonalColumnWidth,
      canSelect,
      classes.headCell,
      classes.visuallyHidden,
      handleRequestSort,
      order,
      orderBy,
      props.actionButton,
      rowCount,
      selected.length,
      selectedCount,
      wrappedRow,
    ]
  );

  const isSelected = useCallback(
    row => {
      return isSelectedFn ? isSelectedFn(row) : selected.indexOf(row.id) !== -1;
    },
    [isSelectedFn, selected]
  );

  const virtualCellRenderer = useCallback(
    ({cellData, columnIndex, rowIndex}) => {
      // header cell item
      const item = virtualHeaderCellItems[columnIndex];
      let row = virtualRows[rowIndex];
      const labelId = `data-table-checkbox-${rowIndex}`;
      const isItemSelected = isSelected(row);
      const key = item.name;
      const itemUploaded = uploadRows.find(item => item.id === row.id);

      if (row.wrappedRow) {
        if (columnIndex > 0) return null;
        row = virtualRows[rowIndex > 0 ? rowIndex - 1 : rowIndex + 1];
        return (
          <WrappedRowCell
            renderSubRows={renderSubRows}
            row={row}
            classes={classes}
          />
        );
      }

      if (item.extraColumn) {
        // const wrappedIndex = rows.findIndex(r => r.id === row.id);
        return getWrappedRowContent({
          row,
          rowIndex,
          cellProps: {
            component: 'div',
            variant: 'body',
          },
          // get item index in the origin rows
          resolveIndex: index => row.vWrappedIndex,
          wrap: false,
        });
      }

      return renderTableCellValue({
        key,
        itemUploaded,
        isItemSelected,
        row,
        name: item.name,
        valueProperty: item.valueProperty,
        wrap: item.wrap,
        renderValue: item.renderValue,
      });
    },
    [
      virtualHeaderCellItems,
      virtualRows,
      isSelected,
      uploadRows,
      renderTableCellValue,
      renderSubRows,
      classes,
      getWrappedRowContent,
    ]
  ); // do not add wrappedRowHeights  here

  const virtualTableRef = useRef();

  useEffect(() => {
    if (
      expandInitiated &&
      virtualTableRef &&
      virtualTableRef.current &&
      expandedRows.length > 0
    ) {
      virtualTableRef.current.recomputeRowHeights();
      virtualTableRef.current.forceUpdate();
    }
  }, [virtualTableRef, expandInitiated, expandedRows]);

  const virtualWidths = useMemo(() => {
    if (!useVirtualizedTable) {
      return [];
    }
    // substract extra columns
    const totalWidth = props.virtualTableWidth - additonalColumnWidth;
    return virtualHeaderCellItems.map(colDef => {
      if (colDef.extraColumn) {
        return additonalColumnWidth;
      }
      return (colDef.virtualWidth * totalWidth) / 100;
    });
  }, [
    additonalColumnWidth,
    virtualHeaderCellItems,
    props.virtualTableWidth,
    useVirtualizedTable,
  ]);

  const computeVirtualRowHeight = useCallback(
    ({index: rowIndex, ...abc}) => {
      const virtualRow = virtualRows[rowIndex];
      if (virtualRow && virtualRow.height > 0) {
        return virtualRow.height;
      }

      return props.virtualRowHeight;
    },
    [props.virtualRowHeight, virtualRows]
  );

  // computed value for optimization, rowHeight will be
  // - a function if there is an expanded row
  // - a scalar value otherwise
  const virtualRowHeightProp = useMemo(() => {
    return expandedRows.length > 0
      ? computeVirtualRowHeight
      : props.virtualRowHeight;
  }, [computeVirtualRowHeight, props.virtualRowHeight, expandedRows]);

  // Data is loading at this moment
  // display only if data is empty
  if (loading) {
    return (
      <AnimatePresence initial={false}>
        <motion.div
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={animationVariants}
          transition={{ease: 'easeOut', duration: 3}}
        >
          <Paper className={classes.loadingWrapper} elevation={0}>
            <CircularProgress disableShrink />
          </Paper>
        </motion.div>
      </AnimatePresence>
    );
  }

  //Data is empty
  if (empty) {
    return (
      <Paper className={classes.emptyWrapper} elevation={0}>
        <Typography variant="h3" color="primary">
          {intl.formatMessage({id: 'dashboard.no_results'})}
        </Typography>
      </Paper>
    );
  }

  return (
    <div className={clsx(classes.root, className)}>
      {bulkOpacity && <div className="opacity-backdrop" />}
      <TableContainer className={classes.tableContainer}>
        {loading && (
          <Box className={classes.tableLoadingWrapper}>
            <CircularProgress />
          </Box>
        )}

        {useVirtualizedTable && (
          <WindowScroller {...props.windowScrollerProps}>
            {({height, scrollTop, onChildScroll}) => (
              <VirtualTable
                ref={virtualTableRef}
                autoHeight
                height={height}
                width={props.virtualTableWidth ?? 123}
                rowHeight={virtualRowHeightProp}
                headerHeight={props.virtualHeaderHeight ?? 48}
                rowCount={virtualRows.length}
                rowGetter={({index}) => virtualRows[index]}
                scrollTop={scrollTop}
                onScroll={onChildScroll}
                className={clsx(classes.table, bulkOpacity && 'bulk-opacity', {
                  wrappable: wrappedRow,
                })}
                aria-label="Data table"
                onRowClick={({event, index}) => {
                  let vrow = virtualRows[index];
                  if (vrow.wrappedRow && !vrow.id) {
                    vrow = virtualRows[index - 1];
                  }
                  const rowIndex = rows.findIndex(
                    r => vrow && r.id === vrow.id
                  );
                  onRowClick && onRowClick(rowIndex, event);
                }}
                style={{
                  '--virtual-row-inner-height': `${props.virtualRowHeight - 8}px`,
                }}
              >
                {virtualHeaderCellItems.map(
                  ({name, label, ...other}, index) => {
                    return (
                      <Column
                        key={name}
                        dataKey={name}
                        label={label}
                        width={(virtualWidths && virtualWidths[index]) ?? 123}
                        headerRenderer={headerProps => {
                          return virtualHeaderRenderer({
                            name,
                            label,
                            ...other,
                            ...headerProps,
                            columnIndex: index,
                          });
                        }}
                        cellRenderer={virtualCellRenderer}
                        className={other.cellClassName}
                      />
                    );
                  }
                )}
              </VirtualTable>
            )}
          </WindowScroller>
        )}

        {!useVirtualizedTable && (
          <Table
            className={clsx(classes.table, bulkOpacity && 'bulk-opacity', {
              wrappable: wrappedRow,
            })}
            aria-labelledby="tableTitle"
            aria-label="Data table"
          >
            <DataTableHead
              wrappedRow={wrappedRow}
              canSelect={canSelect}
              canPauseStart={canPauseStart}
              classes={classes}
              actionButton={props.actionButton}
              headCells={headerCellItems}
              numSelected={selectedCount ? selectedCount : selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              totalRows={total}
            />
            {bulkActions &&
              (showBulkActions || (selected && selected.length > 0)) &&
              (renderBulkActions ? (
                renderBulkActions({
                  headCells: props.headCells,
                  selected: selected,
                  total: total,
                  ...bulkProps,
                })
              ) : (
                <BulkActions
                  headCells={props.headCells}
                  selected={selected}
                  total={total}
                  selectAll={selectAll}
                  onSelectAllList={onSelectAllList}
                  clearSelection={clearSelection}
                  setOpacityState={setBulkOpacity}
                  {...bulkProps}
                />
              ))}
            <TableBody className={clsx({'clickable-row': clickableRow})}>
              {renderedRows}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <TablePagination
        className={clsx(classes.tablePagination, 'pagination-row')}
        rowsPerPageOptions={rowPerPageOptions}
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        bulkOpacity={bulkOpacity}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
};

DataTable.propTypes = {
  /**
   * TRUE is rows contain checkbox column, default true
   */
  canSelect: PropTypes.bool,
  /**
   * Table data is empty
   */
  empty: PropTypes.bool.isRequired,
  /**
   * Table data is loading now
   */
  loading: PropTypes.bool.isRequired,
  /**
   * Bulk actions is shown when items selected
   */
  bulkActions: PropTypes.bool,
  /**
   * It TRUE then table has cells that will shown in additional row below
   * NOTE: only rows with property `rowIsExpandable` will have a expand/collapse icon
   */
  wrappedRow: PropTypes.bool,
  /**
   * Parameters for bulk actions block
   */
  bulkProps: PropTypes.shape({
    /**
     * User/Product, it was used for string labels
     */
    entitiesName: PropTypes.string,
    /**
     * Bulk action list
     */
    actionList: PropTypes.arrayOf(PropTypes.string),
    /**
     * Bulk action type
     */
    actionType: PropTypes.arrayOf(PropTypes.string),
    /**
     * Bulk action value (if TRUE that is visible)
     */
    actionValue: PropTypes.bool,
    successCount: PropTypes.number,
    ignoredCount: PropTypes.number,
    /**
     * Error details
     */
    errorDetails: PropTypes.arrayOf(
      PropTypes.shape({
        field1: PropTypes.string,
        field2: PropTypes.string,
        msg: PropTypes.string,
      })
    ),
  }),
  /**
   * Table head description array
   */
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * column name
       */
      name: PropTypes.string.isRequired,
      /**
       * Column is numeric (if TRUE it will be right aligned)
       */
      numeric: PropTypes.bool,
      /**
       * If TRUE then left padding is not added
       */
      disablePadding: PropTypes.bool,
      /**
       * Column text label
       */
      label: PropTypes.string.isRequired,
      /**
       * Cell rendering procedure
       */
      renderValue: PropTypes.func,
      /**
       * Make this field collapsed in mobile view
       */
      collapse: PropTypes.bool,
      /**
       * Shown this field like first in mobile
       */
      mobileFirst: PropTypes.bool,
    })
  ).isRequired,
  /**
   * Table data
   * contains array of objects with the same fields as headCells object
   */
  rows: PropTypes.arrayOf(PropTypes.object),
  /**
   * Additional table rows
   * contains array of objects with the same fields as headCells object PLUS upload level value (max 100)
   */
  uploadRows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      uploadLevel: PropTypes.number,
    })
  ),
  /**
   * Additional action button shown on mobile view
   */
  actionButton: PropTypes.object,
  /**
   * Row click action
   */
  onRowClick: PropTypes.func,
  /**
   * Custom row click action
   */
  onCustomRowClick: PropTypes.func,
  /**
   * Current pagination page
   */
  currentPage: PropTypes.number,
  /**
   * Total items, used for pagination
   */
  total: PropTypes.number,
  /**
   * Main callback for the datatable change,
   * fired on pagination change (page, rowsPerPage) and on sorting change (sortBy, sortDirection)
   */
  onChange: PropTypes.func,
  /**
   * Default order, whether 'asc', 'desc'
   * asc is used as default value
   */
  defaultOrder: PropTypes.oneOf(['asc', 'desc']),
  /**
   * row per page options
   */
  rowPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  /**
   * Default order by column
   */
  defaultOrderBy: PropTypes.string,
  /**
   * Default row per page value
   */
  defaultRowPerPage: PropTypes.number,
  /**
   * Mobile view max width breakpoint
   */
  mobileBreakPoint: PropTypes.number,
  selectedItems: PropTypes.array,
  onSelectedItemsChange: PropTypes.func,
  renderBulkActions: PropTypes.func,
  isProcessingBulk: PropTypes.bool,
  isSelectedFn: PropTypes.func,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  onSelectAllList: PropTypes.func,
  onClearSelect: PropTypes.func,
  showBulkActions: PropTypes.bool,
  selectedCount: PropTypes.number,
  clickableRow: PropTypes.bool,
  /**
   * Show a pause/start button on rows with `canBePaused` at true
   */
  canPauseStart: PropTypes.bool,
  /**
   * Show retry button if payout is failed
   */
  canRetry: PropTypes.bool,
};

const defaultBulkProps = {};

DataTable.defaultProps = {
  rowPerPageOptions: [25, 50, 100],
  defaultOrder: 'asc',
  defaultRowPerPage: 25,
  mobileBreakPoint: TABLE_DEFAULT_BREAKPOINT,
  isProcessingBulk: false,
  showBulkActions: false,
  uploadRows: [],
  clickableRow: true,
  wrappedRow: false,
  additionalContent: false,
  canSelect: true,
  bulkActions: false,
  bulkProps: defaultBulkProps,
  className: '',
};

export default injectIntl(DataTable);
