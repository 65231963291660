import get from 'lodash.get';
import {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {API_ADMIN_SALE_DETAILS_REQUESTED} from '../../constants/actionTypes';
import useTicketHelper from '../ticket/useTicketHelper';
import useDateHelper from '../utils/useDateHelper';
import {cardIsExpired} from '../../utils/functions';

export default function useSaleDetails(id) {
  const intl = useIntl();
  const {details: sale, sellerPaymentMethods} = useSelector(
    state => state.saleReducer
  );
  const dispatch = useDispatch();
  const ticketHelper = useTicketHelper();
  const [showPaperDelivery, setShowPaperDelivery] = useState(false);
  const [showMticketDelivery, setShowMticketDelivery] = useState(false);
  const [showEticketDelivery, setShowEticketDelivery] = useState(false);
  const ticket = sale && sale.ticket;
  const [showInstantConfirmation, setShowInstantConfirmation] = useState(false);
  const [showTimeToConfirm, setShowTimeToConfirm] = useState(false);
  const [timeToConfirm, setTimeToConfirm] = useState('');
  const [showConfirmedIn, setShowConfirmedIn] = useState(false);
  const [confirmedIn, setConfirmedIn] = useState('');
  const dateHelper = useDateHelper();
  const [showDeliverTimeLeft, setShowDeliverTimeLeft] = useState(false);
  const [deliverTimeLeft, setDeliverTimeLeft] = useState('');
  const notFound = useSelector(state =>
    state.saleReducer.notFound.includes(id)
  );

  const fetchSaleDetails = useCallback(
    id => {
      dispatch({
        type: API_ADMIN_SALE_DETAILS_REQUESTED,
        payload: {
          id,
        },
        meta: {
          notFoundMessage: intl.formatMessage({
            id: 'dashboard.sales.details.sale_not_found',
          }),
        },
      });
    },
    [dispatch, intl]
  );

  useEffect(() => {
    fetchSaleDetails(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (sale) {
      const instantConfirm = get(sale, 'seller.instant_confirm');
      const isPaper = ticket && ticket.type === 'paper';
      const isCollectionPending = sale.status === 'collection-pending';
      const isDeliveryPending = sale.status === 'delivery-pending';

      if (ticket) {
        setShowPaperDelivery(ticket.type === 'paper');
        setShowMticketDelivery(ticket.type === 'm-ticket');
        setShowEticketDelivery(ticket.type === 'e-ticket');
      }
      setShowTimeToConfirm(['confirmation-pending'].includes(sale.status));
      const timeKeys = ['day', 'hour', 'min'];
      const formatPendingData = data => {
        if (data) {
          const res = [];
          for (const key of timeKeys) {
            if (data[key]) {
              res.push(data[key] + key[0]);
            }
          }
          if (res.length > 0) {
            return res.join(' ');
          }
        }
        return '';
      };
      setTimeToConfirm(sale.remainingTimeOnConfirmationPendingData);
      setShowInstantConfirmation(instantConfirm);
      // status included for confirmed sale
      if (
        ['delivered', 'terminated'].includes(sale.status) &&
        !instantConfirm &&
        sale.confirmationDate
      ) {
        setShowConfirmedIn(true);
        setConfirmedIn(
          dateHelper.format(sale.confirmationDate, 'DD/MM/YYYY hh:mm a')
        );
      }
      setShowDeliverTimeLeft(
        (isCollectionPending && !isPaper) || (isPaper && isDeliveryPending)
      );
      setDeliverTimeLeft(
        formatPendingData(sale.remainingTimeOnCollectionPendingData)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sale]);

  return {
    notFound,
    sale,
    sellerPaymentMethods: sellerPaymentMethods.filter(
      item => !cardIsExpired(item)
    ),
    ticket,
    event: ticketHelper.getSaleEvent(sale),
    showPaperDelivery,
    showMticketDelivery,
    showEticketDelivery,
    showInstantConfirmation,
    showTimeToConfirm,
    timeToConfirm,
    showConfirmationMissed: sale.status === 'cancelled',
    showConfirmedIn,
    confirmedIn,
    showDeliverTimeLeft,
    deliverTimeLeft,
    isDelivered: ['delivered', 'terminated', 'annulled'].includes(sale.status),
    showDeliveryDetails: [
      'annulled',
      'delivery-pending',
      'delivered',
      'terminated',
    ].includes(sale.status),
    showUserAddress: ['paper'].includes(ticket && ticket.type),
    fetchSaleDetails,
  };
}
