import {useUploadToPresignedUrlMutation} from '../uploadApi';
import {
  PresignedUrlResult,
  useGeneratePresignedUrlMutation,
} from '../eventsApi';
import {useCallback} from 'react';

export function isPresignedUrlResponse(
  result: unknown
): result is {data: PresignedUrlResult} {
  return typeof result === 'object' && result != null && 'data' in result;
}

export interface IFileField {
  file: File;
  url: string;
}

type UploadFileToS3Prop = {
  entity?: string;
};

export function useUploadFileToS3({entity}: UploadFileToS3Prop = {}) {
  const {upload, isUploading} = useUploadToPresignedUrlMutation();
  const [generatePresignedUrl, {isLoading: isGeneratingPresignedUrl}] =
    useGeneratePresignedUrlMutation();

  const uploadFileToS3 = useCallback(
    async (fileToUpload?: IFileField | null) => {
      if (!fileToUpload) {
        return;
      }
      const fileName = fileToUpload.file.name;

      const result = await generatePresignedUrl({
        objectName: fileName,
        entity: entity,
      });

      if (!isPresignedUrlResponse(result)) {
        return;
      }

      const {uploadUrl, readUrl} = result.data;
      await upload({
        presignedUrl: uploadUrl,
        file: fileToUpload.file,
        contentType: fileToUpload.file.type,
      });
      return readUrl;
    },
    [upload, generatePresignedUrl, entity]
  );

  return {
    upload: uploadFileToS3,
    isLoading: isUploading || isGeneratingPresignedUrl,
  };
}
