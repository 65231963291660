import {cloneDeep} from 'lodash';
import {get} from 'lodash';
import BigNumber from 'bignumber.js';
import {
  STATUS_COLLECTION_PENDING,
  STATUS_TRANSFER_PENDING,
  STATUS_UPLOAD_PENDING,
} from '../constants/sales';

export function getSellingPrice(sale) {
  const {data = {}, sellerAmount, units = 0} = sale || {};
  let sellingPrice = sellerAmount;

  if (sellerAmount === undefined || sellerAmount === null) {
    let desiredPrice = data.desiredPrice;
    if (!desiredPrice) {
      desiredPrice = get(sale, 'ticket.desiredPrice', 0);
    }
    if (desiredPrice) {
      sellingPrice = +desiredPrice * units;
    }
  }

  return sellingPrice;
}

export function calculateMaxPenaltyAmount(sale) {
  if (!sale) {
    return null;
  }
  const {remainingAmountAvailable: payoutAmount = 0} = sale || {};

  // Disable penalty on current sale when payout is `paid-out`, `in-transit` or `balance-due`
  const payoutStatus = get(sale, 'payout.status');
  if (
    payoutStatus &&
    ['paid-out', 'balance-due', 'in-transit'].includes(payoutStatus)
  ) {
    return 0;
  }

  // Cannot add penalty on current sale for `cancelled` sales
  if (['cancelled'].includes(sale.status)) {
    return 0;
  }

  return Math.max(0, payoutAmount);
}

export const getSaleStatus = sale => {
  if (
    sale.ticket?.type === 'e-ticket' &&
    sale.status === STATUS_COLLECTION_PENDING
  ) {
    return STATUS_UPLOAD_PENDING;
  } else if (
    sale.ticket?.type === 'm-ticket' &&
    sale.status === STATUS_COLLECTION_PENDING
  ) {
    return STATUS_TRANSFER_PENDING;
  }

  return sale.status;
};

export const applyFn = (fn, obj, fields = [], precision = 2) => {
  if (!obj || !Array.isArray(fields) || fields.length === 0) {
    return obj;
  }

  const res = cloneDeep(obj);
  const originalAmounts = {};

  for (const amount of fields) {
    if (res[amount]) {
      originalAmounts[amount] = res[amount];
      res[amount] = fn.call(null, res[amount], precision);
    }
  }

  res.originalAmounts = originalAmounts;

  return res;
};

export const roundAmount = (value, precision) => {
  const n = BigNumber(10).pow(precision).toNumber();

  return BigNumber(Math.floor(BigNumber(value).multipliedBy(n).toNumber()))
    .dividedBy(n)
    .toNumber();
};

export const roundAmounts = (obj, fields = []) =>
  applyFn(roundAmount, obj, fields);

export const truncateAmount = (value, precision) => {
  const parsed = parseFloat(value);
  if (isNaN(parsed)) {
    return 0;
  }
  const n = BigNumber(10).pow(precision).toNumber();

  return BigNumber(Math.floor(BigNumber(value).multipliedBy(n).toNumber()))
    .dividedBy(n)
    .toNumber();
};

export const truncateAmounts = (obj, fields = []) =>
  applyFn(truncateAmount, obj, fields);

export const truncateSaleAmounts = sale => {
  if (!sale) {
    return sale;
  }

  let res = truncateAmounts(sale, [
    'total_with_charges',
    'totalWithCharges',
    'totalWithoutCharges',
    'platformRefundsAmount',
    'platformRefundSellingAmount',
    'platformRefundHandlingAmount',
  ]);

  res = roundAmounts(sale, ['fullHandlingFees', 'handlingFees']);

  if (Array.isArray(sale.platformRefunds)) {
    res.platformRefunds = sale.platformRefunds.map(refund =>
      truncateAmounts(refund, ['sellingAmount', 'amount', 'handlingAmount'])
    );
  }

  return res;
};

/**
 * Merge array of sale penalty payments by salePenalty sale id
 *
 * @param {array} salePenaltyPayments
 * @returns
 */
export const mergeSalePenaltyPayments = (salePenaltyPayments = []) =>
  Object.values(
    salePenaltyPayments.reduce((res, {id, ...item}) => {
      // group by sale
      const key = item?.salePenalty?.sale?.id;
      if (!key) {
        return res;
      }
      if (!res[key]) {
        res[key] = {...item, id: [], amount: 0};
      }
      // accumulate id
      res[key].id.push(id);
      // accumulate amount
      res[key].amount = BigNumber.sum(
        res[key].amount || 0,
        item.amount || 0
      ).toNumber();

      return res;
    }, {})
    // id will be all merged items id separated with '-'
  ).map(({id, ...item}) => ({...item, id: id.join('-')}));
