import {Typography} from '@material-ui/core';
import React from 'react';
import {useIntl} from 'react-intl';
import useDateHelper from '../../../hooks/utils/useDateHelper';

interface IDeliveryDetailsProps {
  status: string;
  deliveryDate: string | null;
  defaultMessage?: string;
  statusSetByAdmin: string | null;
  adminName: string | null;
}

const DeliveryDetails = ({
  status,
  deliveryDate,
  defaultMessage = 'delivery_date',
  statusSetByAdmin = null,
  adminName = null,
}: IDeliveryDetailsProps) => {
  const intl = useIntl();
  const dateHelper = useDateHelper();

  if (status === 'delivered' && status === statusSetByAdmin) {
    const name = adminName ?? 'Admin';
    const message = intl.formatMessage(
      {id: 'dashboard.sales.details.delivered_by_admin_at_date'},
      {
        admin: name,
        date: dateHelper.format(deliveryDate, 'DD/MM/YYYY'),
        time: dateHelper.format(deliveryDate, 'LT'),
      }
    );

    return <Typography variant="body2">{message}</Typography>;
  }

  return (
    <Typography variant="body2">
      {intl.formatMessage({id: 'dashboard.sales.details.' + defaultMessage})}:{' '}
      {dateHelper.format(deliveryDate, 'DD/MM/YYYY HH:mm')}
    </Typography>
  );
};

export default DeliveryDetails;
